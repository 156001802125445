import styled from 'styled-components';

export const StyledRatingWrapper = styled.div`
  width: fit-content;
  margin-right: 15px;
  margin-left: auto;
  padding: 12px 15px 16px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.color.general.lightest};
  text-align: center;
`;

export const StyledRatingWrite = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;