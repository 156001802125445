import React from 'react';
import { IPropsSvgIcon } from '../../types';

export const bot = ({ width, height }: IPropsSvgIcon) => (
	<svg width={width || 38} height={height || 38} viewBox="0 0 38 38" fill="none">
		<g clipPath="url(#clip0_390_104907)">
			<circle cx="18" cy="18" r="18" fill="#DADADA" />
			<g filter="url(#filter0_d_390_104907)">
				<path
					d="M17.9996 18.1342C15.8807 18.1342 14.1631 18.3984 14.1631 18.7244C14.1631 19.0504 15.8808 19.3951 17.9996 19.3951C20.1186 19.3951 21.8362 19.0504 21.8362 18.7244C21.8363 18.3984 20.1186 18.1342 17.9996 18.1342Z"
					fill="#5E5E5E"
				/>
				<path
					d="M15.9749 18.2231C13.3526 18.4745 13.7349 19.0514 16.162 19.3042C15.0532 18.9966 14.8248 18.542 15.9749 18.2231Z"
					fill="#9D9D9D"
				/>
				<path
					d="M11.3792 11.0378H10.0904C9.82473 11.0378 9.60742 11.2551 9.60742 11.5207V13.4256C9.60742 13.6912 9.82473 13.9085 10.0904 13.9085H11.3373C11.0391 12.9769 11.0519 11.966 11.3792 11.0378Z"
					fill="#5E5E5E"
				/>
				<path
					d="M10.437 13.4256V11.5207C10.437 11.2551 10.6543 11.0378 10.9199 11.0378H10.0904C9.82473 11.0378 9.60742 11.2551 9.60742 11.5207V13.4256C9.60742 13.6912 9.82473 13.9085 10.0904 13.9085H10.9199C10.6543 13.9085 10.437 13.6912 10.437 13.4256Z"
					fill="#9D9D9D"
				/>
				<path
					d="M25.91 11.0378H24.6211C24.9477 11.9641 24.9618 12.975 24.663 13.9085H25.91C26.1756 13.9085 26.3929 13.6912 26.3929 13.4256V11.5207C26.3929 11.2551 26.1755 11.0378 25.91 11.0378Z"
					fill="#5E5E5E"
				/>
				<path
					d="M25.5875 11.0378H24.6211C24.9477 11.9641 24.9618 12.975 24.663 13.9085H25.6294C25.9276 12.9769 25.9148 11.966 25.5875 11.0378Z"
					fill="#9D9D9D"
				/>
				<path
					d="M24.9573 12.5354C24.9573 10.0409 22.9163 8 20.4219 8H15.5773C13.0846 8 11.042 10.0392 11.042 12.5354C11.042 15.0298 13.0829 17.0708 15.5774 17.0708H20.422C22.9146 17.0707 24.9573 15.0315 24.9573 12.5354Z"
					fill="#9D9D9D"
				/>
				<path
					d="M11.8715 12.5354C11.8715 10.0409 13.9124 8 16.4069 8H15.5773C13.087 8 11.042 10.0368 11.042 12.5354C11.042 15.0298 13.0829 17.0708 15.5774 17.0708H16.4069C13.9124 17.0707 11.8715 15.0298 11.8715 12.5354Z"
					fill="white"
					fillOpacity="0.15"
				/>
				<path
					d="M20.2214 9.35596C19.6878 9.35596 16.4535 9.35596 15.779 9.35596C14.0303 9.35596 12.5996 10.7867 12.5996 12.5353C12.5996 14.284 14.0304 15.7147 15.779 15.7147C16.4449 15.7147 19.6843 15.7147 20.2214 15.7147C21.9701 15.7147 23.4008 14.284 23.4008 12.5353C23.4008 10.7867 21.9701 9.35596 20.2214 9.35596Z"
					fill="#2D2D2D"
				/>
				<path
					d="M13.4414 12.5354C13.4414 10.7867 14.8721 9.35602 16.6207 9.35602H15.7673C14.0186 9.35602 12.5879 10.7867 12.5879 12.5354C12.5879 14.2841 14.0186 15.7148 15.7673 15.7148H16.6207C14.8721 15.7148 13.4414 14.284 13.4414 12.5354Z"
					fill="white"
					fillOpacity="0.15"
				/>
				<path
					d="M15.8241 13.5999C16.4464 13.5999 16.9509 13.0954 16.9509 12.4731C16.9509 11.8508 16.4464 11.3463 15.8241 11.3463C15.2017 11.3463 14.6973 11.8508 14.6973 12.4731C14.6973 13.0954 15.2017 13.5999 15.8241 13.5999Z"
					fill="#FF5F00"
				/>
				<path
					d="M20.1756 13.5999C20.7979 13.5999 21.3024 13.0954 21.3024 12.4731C21.3024 11.8508 20.7979 11.3463 20.1756 11.3463C19.5533 11.3463 19.0488 11.8508 19.0488 12.4731C19.0488 13.0954 19.5533 13.5999 20.1756 13.5999Z"
					fill="#FF5F00"
				/>
				<path
					d="M15.5268 12.4732C15.5268 11.9974 15.8218 11.5907 16.2388 11.4255C16.1104 11.3746 15.9706 11.3464 15.8241 11.3464C15.2017 11.3464 14.6973 11.8509 14.6973 12.4732C14.6973 13.0955 15.2018 13.6 15.8241 13.6C15.9706 13.6 16.1104 13.5717 16.2388 13.5209C15.8218 13.3556 15.5268 12.949 15.5268 12.4732Z"
					fill="white"
					fillOpacity="0.25"
				/>
				<path
					d="M20.5904 11.4255C20.462 11.3746 20.3221 11.3463 20.1756 11.3463C19.5533 11.3463 19.0488 11.8508 19.0488 12.4731C19.0488 13.2676 19.8514 13.8136 20.5904 13.5208C19.6429 13.1454 19.6432 11.8008 20.5904 11.4255Z"
					fill="white"
					fillOpacity="0.25"
				/>
				<path
					d="M21.8031 18.6463C21.8251 18.6718 21.8366 18.6979 21.8366 18.7244C21.8366 19.0504 20.1189 19.3146 18 19.3146C15.6869 19.3146 13.8914 19.0015 14.197 18.6462C13.1811 19.6362 12.5498 21.0192 12.5498 22.5498C12.5498 25.5598 14.9899 28 18 28C21.0101 28 23.4502 25.5598 23.4502 22.5498C23.4502 21.0192 22.819 19.6362 21.8031 18.6463Z"
					fill="#9D9D9D"
				/>
				<path
					d="M14.6777 19.0197C14.4491 18.959 14.0431 18.8252 14.197 18.6462C13.1811 19.6362 12.5498 21.0192 12.5498 22.5498C12.5498 25.5598 14.99 28 18 28C18.1396 28 18.2779 27.9947 18.4148 27.9844C13.975 27.6503 11.8041 22.3958 14.6777 19.0197Z"
					fill="white"
					fillOpacity="0.15"
				/>
				<path
					d="M16.3057 20.6256C16.3264 20.8431 16.3331 20.802 17.6584 22.2152C17.8462 22.403 18.1536 22.403 18.3414 22.2152C19.6671 20.8016 19.6734 20.8433 19.6942 20.6256C18.6009 20.7233 17.4269 20.7258 16.3057 20.6256Z"
					fill="#FF5F00"
				/>
				<path
					d="M17.2414 20.6849C16.9221 20.6725 16.6095 20.6528 16.3057 20.6256C16.3264 20.8431 16.3331 20.802 17.6584 22.2152C17.8463 22.403 18.1536 22.403 18.3414 22.2152L18.4629 22.0856C17.2611 20.8042 17.2796 20.8567 17.2414 20.6849Z"
					fill="white"
					fillOpacity="0.25"
				/>
				<path
					d="M22.8493 20.061C22.578 19.5337 22.2236 19.0562 21.8029 18.6463C21.8249 18.6718 21.8364 18.6979 21.8364 18.7244C21.8364 19.0504 20.1187 19.3146 17.9998 19.3146C15.6867 19.3146 13.8912 19.0015 14.1968 18.6462C13.7761 19.0562 13.4216 19.5337 13.1504 20.061C15.7679 21.0153 20.2305 21.0158 22.8493 20.061Z"
					fill="#2D2D2D"
				/>
				<path
					d="M13.1504 20.061C13.3733 20.1423 13.6139 20.2179 13.8701 20.2872C14.0807 19.8268 14.3532 19.4007 14.6775 19.0197C14.4496 18.9592 14.0434 18.8246 14.1968 18.6462C13.7869 19.0457 13.427 19.5234 13.1504 20.061Z"
					fill="white"
					fillOpacity="0.25"
				/>
			</g>
		</g>
		<circle cx="31" cy="31" r="5" fill="#12B357" />
		<rect x="25" y="25" width="12" height="12" rx="6" stroke="white" strokeWidth="2" />
		<defs>
			<filter
				id="filter0_d_390_104907"
				x="-7"
				y="7"
				width="50"
				height="50"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="14" />
				<feGaussianBlur stdDeviation="7.5" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_390_104907" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_390_104907" result="shape" />
			</filter>
			<clipPath id="clip0_390_104907">
				<rect width="36" height="36" rx="17.5" fill="white" />
			</clipPath>
		</defs>
	</svg>
);