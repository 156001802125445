import React from 'react';
import dayjs from 'dayjs';
import useResizeObserver from 'use-resize-observer';

import { useWidget } from '../../WidgetProvider';
import WidgetButton from './WidgetButton';
import WidgetButtonForms from './WidgetButtonForms';
import { usePrevious } from '../../hooks/useReact';
import toggleWidgetVisibility from '../../utils/toggleWidgetVisibility';

const isBetween = require('dayjs/plugin/isBetween');

dayjs.extend(isBetween);

const WidgetButtonContainer = () => {
	const { isTwoButtonsOnOneSide, widgetOpened } = useWidget();
	const prevWidgetOpened = usePrevious(widgetOpened);

	useResizeObserver({
		ref: document.getElementById('skibble-widget-root'),
		onResize() {
			if (isTwoButtonsOnOneSide) {
				return;
			}

			toggleWidgetVisibility({
				widgetOpened,
				prevWidgetOpened,
			});
		},
	});

	return (
		<>
			<WidgetButton className={widgetOpened ? 'liveChatIcon displayNone' : 'liveChatIcon'} />
			<WidgetButtonForms />
		</>
	);
};

export default WidgetButtonContainer;