import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import WidgetProvider from './WidgetProvider';

const block = document.getElementById(
	new URLSearchParams(window.location.search)?.get('iframeType') === 'callback'
		? 'skibble-callback-root'
		: 'skibble-widget-root',
) as HTMLDivElement;

const root = createRoot(block);

root.render(
	<WidgetProvider>
		<App />
	</WidgetProvider>,
);