import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const typeQuestionWithNotifications = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg
			id="Layer_2"
			data-name="Layer 2"
			width={width || 59}
			height={height || 59}
			viewBox="0 0 59 59"
			fill="none"
		>
			<defs>
				<style>
					{`.cls-1, .cls-2 {
          fill: ${theme.color.general.white};
        }

          .cls-3 {
          fill: ${theme.color.status.warning};
        }

          .cls-4 {
          fill: ${fill || theme.color.primary.main};
        }

          .cls-4, .cls-2 {
          fill-rule: evenodd;
        }`}
				</style>
			</defs>
			<g id="Layer_1-2" data-name="Layer 1">
				<circle className="cls-3" cx="51" cy="8" r="8" />
				<path
					className="cls-4"
					d="m55.35,18.32c-1.34.56-2.81.88-4.35.88-6.19,0-11.2-5.01-11.2-11.2,0-1.54.31-3.01.88-4.35-9.61-.99-19.31-.85-28.9.42C6.19,4.8,1.8,9.19,1.06,14.77-.35,25.54-.35,36.46,1.06,47.23c.73,5.58,5.13,9.98,10.71,10.71,10.77,1.42,21.68,1.42,32.45,0,5.58-.73,9.98-5.13,10.71-10.71,1.26-9.59,1.4-19.29.42-28.9Z"
				/>
				<path
					className="cls-2"
					d="m13.1,17.6c.62-.62,1.45-.96,2.32-.96h25.14c.87,0,1.71.35,2.32.96.62.62.96,1.45.96,2.32v19.43c0,.87-.35,1.71-.96,2.32s-1.45.96-2.32.96h-13.09l-6.86,5.49c-.3.24-.71.29-1.06.12-.35-.17-.57-.52-.57-.9v-4.71h-3.57c-.87,0-1.71-.35-2.32-.96-.62-.62-.96-1.45-.96-2.32v-19.43c0-.87.35-1.71.96-2.32Zm2.32,1.04c-.34,0-.67.14-.91.38-.24.24-.38.57-.38.91v19.43c0,.34.14.67.38.91.24.24.57.38.91.38h4.57c.55,0,1,.45,1,1v3.63l5.51-4.41c.18-.14.4-.22.63-.22h13.44c.34,0,.67-.14.91-.38s.38-.57.38-.91v-19.43c0-.34-.14-.67-.38-.91-.24-.24-.57-.38-.91-.38H15.43Z"
				/>
				<path
					className="cls-2"
					d="m28.47,24.4c-.47-.09-.96-.05-1.4.14-.44.18-.82.5-1.09.89-.27.4-.41.87-.41,1.35,0,.55-.45,1-1,1s-1-.45-1-1c0-.88.26-1.73.75-2.46.49-.73,1.18-1.3,1.99-1.63s1.7-.42,2.56-.25c.86.17,1.65.59,2.27,1.21.62.62,1.04,1.41,1.21,2.27.17.86.08,1.75-.25,2.56-.34.81-.9,1.5-1.63,1.99-.45.3-.94.51-1.46.63v.26c0,.55-.45,1-1,1s-1-.45-1-1v-1.14c0-.55.45-1,1-1,.48,0,.95-.14,1.35-.41.4-.27.71-.65.89-1.09s.23-.93.14-1.4c-.09-.47-.32-.9-.66-1.24s-.77-.57-1.24-.66Z"
				/>
				<path
					className="cls-1"
					d="m28,34.79c-.34,0-.67.1-.95.29-.28.19-.5.46-.63.77-.13.31-.16.66-.1.99.07.33.23.64.47.88.24.24.55.4.88.47.33.07.68.03.99-.1.31-.13.58-.35.77-.63.19-.28.29-.61.29-.95,0-.45-.19-.89-.51-1.21-.32-.32-.75-.5-1.21-.51Z"
				/>
			</g>
		</svg>
	);
};