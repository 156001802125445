import React from 'react';

import { StyledLoaderCircular } from './styled';

interface IProps {
	color: string,
	left?: string,
	size?: string,
	top?: string,
}

const Loader = (props: IProps) => {
	const {
		color,
		left,
		size,
		top,
	} = props;

	return (
		<StyledLoaderCircular
			color={color}
			top={top}
			left={left}
			size={size}
			className="loader"
		>
			<div className="loaderBg" />
			<div className="spinnerHolder animate1">
				<div className="loaderSpinner1" />
			</div>
			<div className="spinnerHolder animate2">
				<div className="loaderSpinner2" />
			</div>
		</StyledLoaderCircular>
	);
};

export default Loader;