import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const typeBubblesWithNotifications = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg
			id="Layer_2"
			data-name="Layer 2"
			width={width || 59}
			height={height || 59}
			viewBox="0 0 59 59"
			fill="none"
		>
			<defs>
				<style>
					{`.cls-1 {
          fill: ${theme.color.status.warning};
        }

          .cls-2 {
          fill: ${fill || theme.color.primary.main};
        }

          .cls-2, .cls-3 {
          fill-rule: evenodd;
        }

          .cls-3 {
          fill: ${theme.color.general.white};
        }`}
				</style>
			</defs>
			<g id="Layer_1-2" data-name="Layer 1">
				<circle className="cls-1" cx="51" cy="8" r="8" />
				<path
					className="cls-2"
					d="m55.35,18.32c-1.34.56-2.81.88-4.35.88-6.19,0-11.2-5.01-11.2-11.2,0-1.54.31-3.01.88-4.35-9.61-.99-19.31-.85-28.9.42C6.19,4.8,1.8,9.19,1.06,14.77-.35,25.54-.35,36.46,1.06,47.23c.73,5.58,5.13,9.98,10.71,10.71,10.77,1.42,21.68,1.42,32.45,0,5.58-.73,9.98-5.13,10.71-10.71,1.26-9.59,1.4-19.29.42-28.9Z"
				/>
				<path
					className="cls-3"
					d="m16.89,17.7c-.22,0-.44.09-.6.25-.16.16-.25.37-.25.6v16.62c0,.11-.02.21-.05.32l-1.23,3.68,1.93-.61c.53-.17,1.09.13,1.25.65.17.53-.13,1.09-.65,1.25l-3.8,1.2c-.36.11-.75.02-1.01-.25-.26-.27-.35-.66-.24-1.02l1.79-5.38v-16.45c0-.75.3-1.48.83-2.01.53-.53,1.26-.83,2.01-.83h18.46c.75,0,1.48.3,2.01.83.53.53.83,1.26.83,2.01v5.66c0,.55-.45,1-1,1s-1-.45-1-1v-5.66c0-.22-.09-.44-.25-.6-.16-.16-.37-.25-.6-.25h-18.46Z"
				/>
				<path
					className="cls-3"
					d="m39.6,29.7c.21,0,.38.07.49.16.1.08.11.16.11.19v12.18c0,.13.02.25.07.37l.92,2.33-4.58-.97c-.07-.01-.14-.02-.21-.02h-12.8c-.21,0-.38-.07-.49-.16-.1-.08-.11-.16-.11-.19v-13.54s.02-.11.11-.19c.1-.09.28-.16.49-.16h16Zm1.78-1.37c-.5-.42-1.14-.63-1.78-.63h-16c-.64,0-1.28.21-1.78.63-.5.42-.82,1.04-.82,1.72v13.54c0,.68.32,1.3.82,1.72.5.42,1.14.63,1.78.63h12.7l6.3,1.33c.36.08.73-.05.97-.33.24-.28.3-.67.17-1.01l-1.53-3.88v-11.99c0-.68-.32-1.3-.82-1.72Z"
				/>
			</g>
		</svg>
	);
};