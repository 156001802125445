const openCallback = () => {
	document.getElementById('callbackWrapper')?.classList.add('extended');
	document.getElementById('callbackWrapper')?.classList.remove('minimised');
};

const closeCallback = () => {
	document.getElementById('callbackWrapper')?.classList.add('minimised');
	document.getElementById('callbackWrapper')?.classList.remove('extended');
};

const openWidget = () => {
	document.getElementById('chatWrapper')?.classList.add('extended');
	document.getElementById('chatWrapper')?.classList.remove('minimised');
};

const closeWidget = () => {
	document.getElementById('chatWrapper')?.classList.add('minimised');
	document.getElementById('chatWrapper')?.classList.remove('extended');
};

interface IParams {
	callbackOpened?: boolean,
	prevCallbackOpened?: boolean,
	widgetOpened?: boolean,
	prevWidgetOpened?: boolean,
}

const toggleWidgetVisibility = (params: IParams) => {
	const {
		callbackOpened,
		prevCallbackOpened,
		widgetOpened,
		prevWidgetOpened,
	} = params;

	if (callbackOpened) {
		openCallback();
		closeWidget();
	} else if (prevCallbackOpened) {
		closeCallback();
	}

	if (widgetOpened) {
		openWidget();
		closeCallback();
	} else if (prevWidgetOpened) {
		closeWidget();
	}
};

export default toggleWidgetVisibility;