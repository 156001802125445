import { tLang } from './utils/getTranslation';

export const FILE_TYPES = {
	images: {
		'image/jpeg': ['.jpg', '.jpeg'],
		'image/png': ['.png'],
	},
	files: {
		'application/pdf': ['.pdf'],
		'text/plain': ['.txt'],
		'application/msword': ['.doc'],
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
		'application/vnd.ms-excel': ['.xls'],
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
		'application/vnd.ms-powerpoint': ['.ppt'],
		'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
		'image/heic': ['.heic'],
	},
};

export const CHAT_EVENTS_TO_SHOW_IN_WIDGET = [
	'operator_typing',
	'contact_message',
	'operator_message',
	'system_message',
	'operator_joined',
	'forwarded_operator',
	'forwarded_department',
	'chat_closed',
	'operator_rated',
];

export const BOTS_URLS_START: Record<string, string> = {
	telegram: 'https://t.me/',
	viber: 'viber://pa?chatURI=',
	// whatsapp: 'https://wa.me/',
	instagram: 'https://www.instagram.com/direct/t/',
	facebook: 'https://m.me/',
};

export const CHAT_EVENTS_LOAD_LIMIT = 40;

export const HEADER_HEIGHT = 56;
export const CHOOSE_CHANNELS_HEADER_HEIGHT = tLang === 'uk' ? 128 : 108;
export const CHOOSE_CHANNELS_FOOTER_HEIGHT = 33;
export const CONTACT_FORM_FOOTER_HEIGHT = 33;
export const CHANNEL_BUTTON_HEIGHT = 54;
export const PAGINATION_TABS_HEIGHT = 56;

export const INPUT_HEIGHT = 44 + 23;
export const CHECKBOX_HEIGHT = tLang === 'en' ? 74 : 90;
export const TEXTAREA_HEIGHT = 140;
export const HEADER_HEIGHT_PRE_CHAT_FORM = tLang === 'en' ? 82 : 100;
export const HEADER_WITH_ARROW_HEIGHT_PRE_CHAT_FORM = 100;
export const CHOOSE_CHANNELS_HEADER_WITH_OFFLINE_HEIGHT = 161;
export const CONTACT_FORM_HEADER_WITH_OFFLINE_HEIGHT = 102;

export const LIVE_CHAT_HEIGHT = 515;
export const KNOWLEDGE_BASE_LIST_HEIGHT = 436;
export const KNOWLEDGE_BASE_LIST_LARGE_HEIGHT = 675;
export const KNOWLEDGE_BASE_ITEM_HEIGHT = 607;
export const KNOWLEDGE_BASE_ITEM_LARGE_HEIGHT = 897;
export const CALLBACK_FORM_HEIGHT = tLang === 'en' ? 254 : 279;
export const MAIN_BUTTON_SIZE = 59;
export const BOTH_BUTTONS_CONTAINER_SIZE = 118 + 20;