import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { IKnowledgeBaseArticle, ScreenType } from '../../../types';
import Icon from '../../../components/atomic/Icon';
import { useWidget } from '../../../WidgetProvider';

const Wrap = styled.div<{ additionalPadding?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${({ additionalPadding }) => (additionalPadding ? '13px 15px 13px 35px' : '13px 10px')};
  border-bottom: 1px solid ${({ theme }) => theme.color.general.lightest};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.color.general.lightest};
  }
`;

const Title = styled.div`
  color: ${({ theme }) => theme.color.general.darkest};
  font-size: 14px;
  line-height: 18px;
`;

const IconArticle = styled.div`
  margin-right: 10px;
`;

interface IProps extends IKnowledgeBaseArticle {
	additionalPadding?: boolean;
	setCurrentScreen: Dispatch<SetStateAction<ScreenType>>;
}

const Article = (props: IProps) => {
	const {
		id,
		title,
		additionalPadding,
		setCurrentScreen,
	} = props;

	const { setKnowledgeBaseItemId } = useWidget();

	const handleClick = () => {
		setKnowledgeBaseItemId(id);
		setCurrentScreen('knowledgeBaseItem');
	};

	return (
		<Wrap additionalPadding={additionalPadding} onClick={handleClick}>
			<IconArticle>
				<Icon name="article" />
			</IconArticle>
			<Title>{title}</Title>
		</Wrap>
	);
};

export default Article;