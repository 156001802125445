import React from 'react';
import clsx from 'clsx';
import { StyledLink } from './styled';

interface IProps {
	children?: React.ReactNode,
	href: string,
	noStyles: boolean,
}

const Link: React.FC<IProps> = (props) => {
	const {
		children,
		href,
		noStyles,
	} = props;

	return (
		<StyledLink
			className={clsx(noStyles && 'noStyles', 'link')}
		>
			<a href={href} target="_blank" rel="noreferrer noopener">
				{children}
			</a>
		</StyledLink>
	);
};

export default Link;