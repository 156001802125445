import React, { useState } from 'react';
import clsx from 'clsx';

import { StyledDropdown, StyledMenu } from './styled';
import useClickOutside from '../../../hooks/useClickOutside';
import Icon from '../Icon';

interface IProps {
	children: React.ReactNode,
	className: string,
}

const DropdownMenu = (props: IProps) => {
	const {
		children,
		className,
	} = props;

	const dropdownRef = React.useRef(null);
	const [menuOpened, setMenuOpened] = useState(false);

	useClickOutside(dropdownRef, () => setMenuOpened(false));

	const onBtnClick = () => {
		setMenuOpened((prev) => !prev);
	};

	return (
		<StyledDropdown className={clsx(menuOpened && 'opened', className)} ref={dropdownRef}>
			<Icon
				onClick={onBtnClick}
				name="threeDotsHollow"
				className="dots"
			/>
			<StyledMenu
				onClick={onBtnClick}
			>
				{children}
			</StyledMenu>
		</StyledDropdown>
	);
};

export default DropdownMenu;