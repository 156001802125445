import React, { useState } from 'react';
import clsx from 'clsx';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import ct from 'countries-and-timezones';
import platform from 'platform';

import WidgetStartHeader from '../ChooseChannels/components/WidgetStartHeader';
import Input from '../../components/atomic/Input';
import Button from '../../components/atomic/Button';
import WidgetFooter from '../ChooseChannels/components/WidgetFooter';
import { StyledChatMessage, StyledChatsWrapper } from '../../components/styled';
import { sendCallbackData } from '../../api';
import { useWidget } from '../../WidgetProvider';
import { getDeviceType } from '../../utils/metadata';
import { getT } from '../../utils/getTranslation';
import TextPanel from '../../components/atomic/TextPanel';

interface IProps {
	className?: string,
}

const CallbackForm = (props: IProps) => {
	const { className } = props;
	const {
		license, contactUid, chatStartedUrl, isNonWorkingTime, setWidgetOpened, widgetSettings,
	} = useWidget();

	const { callback_button_placement, color, is_hide_branding } = widgetSettings?.settings || {};

	const [phone, setPhone] = useState('');
	const [error, setError] = useState('');
	const [serverError, setServerError] = useState<Error | null>(null);
	const [serverSuccess, setServerSuccess] = useState(false);

	const isSubmitPressed = React.useRef(false);

	const getIsFormValid = (value: string) => {
		const errorNotRequire = isPossiblePhoneNumber(value) ? '' : 'invalidPhone';
		const error = !value ? 'required' : errorNotRequire;
		setError(error);
		return error;
	};

	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		if (isSubmitPressed.current) {
			getIsFormValid(value);
		}
		setPhone(value);
	};

	const onSuccess = () => {
		setPhone('');
		setServerSuccess(true);
		const id = setTimeout(() => {
			setWidgetOpened(false, 'callback');
		}, 3000);

		return () => clearTimeout(id);
	};

	const onError = (error: Error) => {
		setServerError(error);
		setServerSuccess(false);
	};

	const onFormSubmit = async () => {
		isSubmitPressed.current = true;
		const error = getIsFormValid(phone);
		if (!error) {
			const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
			sendCallbackData(
				license,
				contactUid,
				{
					phone: `+${phone.replace(/\D/g, '')}`,
					origin: chatStartedUrl,
					timezone,
					country: ct.getCountryForTimezone(timezone)?.id || '',
					browser: `${platform.name} ${platform.version}`,
					os: platform.os?.toString() || '',
					device: getDeviceType(),
					browser_lang: window.navigator.language?.split('-')?.[0],
					cameFrom: 'callback',
				},
			)
				.then(onSuccess)
				.catch(onError);
		}
	};

	return (
		<StyledChatsWrapper
			id="callbackWrapper"
			className={clsx('minimised', is_hide_branding && 'withoutBranding', 'callbackWrapper', className)}
			button_placement={callback_button_placement}
		>
			<WidgetStartHeader
				hideWidget={() => setWidgetOpened(false, 'callback')}
				subtextKey={isNonWorkingTime ? 'teamIsOfflineLiveChatOnlyText' : 'leaveNumberToCallback'}
				currentScreen="callbackRequest"
				headerText={getT('callbackRequest')}
			/>
			{serverError || serverSuccess ? (
				<StyledChatMessage>
					<TextPanel
						content={getT(serverError ? 'serverError' : 'yourCallbackRequestHasBeenSent')}
						padding="20px 0"
						className="chatMessage"
					/>
				</StyledChatMessage>
			) : (
				<>
					<Input
						key="phone"
						label={getT('phone')}
						iconLeftProps={{ name: 'phone' }}
						required
						placeholder="+380"
						className="callbackInput"
						value={phone}
						onChange={onInputChange}
						error={!!error}
					/>
					<Button
						fullWidth
						className="callback"
						backgroundColor={color}
						borderColor={color}
						onClick={onFormSubmit}
					>
						{getT('submit')}
					</Button>
				</>
			)}
			{!is_hide_branding && <WidgetFooter />}
		</StyledChatsWrapper>
	);
};

export default CallbackForm;