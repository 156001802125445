import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const fileSign = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();

	return (
		<svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none">
			<g clipPath="url(#clip0_11190_75449)">
				<path
					d="M14.2858 14.2857C14.2858 14.5888 14.1654 14.8795 13.951 15.0938C13.7367 15.3081 13.446 15.4286 13.1429 15.4286H2.85721C2.55411 15.4286 2.26342 15.3081 2.04909 15.0938C1.83476 14.8795 1.71436 14.5888 1.71436 14.2857V1.71427C1.71436 1.41116 1.83476 1.12047 2.04909 0.906146C2.26342 0.691819 2.55411 0.571411 2.85721 0.571411H8.5715L14.2858 6.2857V14.2857Z"
					stroke={fill || theme.color.general.black}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M6.61544 9.59145C6.77442 9.12378 9.50004 3.99996 7.48309 4.08228C5.46614 4.1646 6.63572 5.17384 8.31047 8.11257C9.36975 9.66849 11.5 11 11.993 9.59175C12.4859 8.18353 8.5 8.49997 7 9.49997C5.5 10.5 5.00004 13 4.08305 11.7863C3.16606 10.5727 6.61544 9.59145 6.61544 9.59145Z"
					stroke={fill || theme.color.general.black}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
				/>
			</g>
		</svg>
	);
};