import React from 'react';
import clsx from 'clsx';
import { useTheme } from 'styled-components';

import { StyledTextPanel } from './styled';
import Icon from '../Icon';
import { IProps as IPropsIcon } from '../Icon/Icon';
import Typography from '../Typography';

interface IProps {
	alignItems?: 'start' | 'end' | 'center' | 'stretch',
	backgroundColor?: string,
	bordered?: string,
	borderColor?: string,
	children?: React.ReactNode,
	className?: string,
	iconLeftProps?: IPropsIcon,
	iconRightProps?: IPropsIcon,
	color?: string,
	content?: string | React.ReactNode,
	fullWidth?: boolean,
	inverted?: boolean,
	justifyContent?: 'start' | 'end' | 'center' | 'stretch' | 'space-around' | 'space-between' | 'space-evenly',
	padding?: string,
	textAlign?: 'inherit' | 'left' | 'center' | 'right' | 'justify',
	title?: string | JSX.Element,
}

const TextPanel = (props: IProps) => {
	const {
		alignItems,
		backgroundColor,
		bordered,
		borderColor,
		children,
		className,
		iconLeftProps,
		iconRightProps,
		color,
		content,
		fullWidth,
		inverted,
		justifyContent,
		padding,
		textAlign,
		title,
	} = props;
	const theme = useTheme();
	const textColor = inverted ? theme.color.general.white : color;

	return (
		<StyledTextPanel
			textAlign={textAlign}
			className={clsx(className, 'panel', (borderColor || bordered) && 'bordered', fullWidth && 'fullWidth')}
			backgroundColor={backgroundColor}
			borderColor={borderColor}
			color={textColor}
			padding={padding}
			justifyContent={justifyContent}
			alignItems={alignItems}
			inverted={inverted}
		>
			{children || (
				<>
					{iconLeftProps && (
						<Icon
							height={15}
							width={15}
							fill={textColor}
							/* eslint-disable-next-line react/jsx-props-no-spreading */
							{...iconLeftProps}
						/>
					)}
					<div className="titleAndContent">
						{title && (
							<Typography
								className="title"
								component="div"
								variant="caption1"
								color={textColor}
							>
								{title}
							</Typography>
						)}
						{content && (
							<Typography
								className="content"
								variant="body2"
								color={textColor}
							>
								{content}
							</Typography>
						)}
					</div>
					{iconRightProps && (
						<Icon
							height={15}
							width={15}
							fill={textColor}
							/* eslint-disable-next-line react/jsx-props-no-spreading */
							{...iconRightProps}
						/>
					)}
				</>
			)}
		</StyledTextPanel>
	);
};

export default TextPanel;

TextPanel.defaultProps = {
	alignItems: 'center',
	justifyContent: 'start',
	textAlign: 'left',
};