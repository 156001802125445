import React from 'react';

import { StyledWidgetStartHeader, StyledWidgetTitle } from '../styled';
import Icon from '../../../components/atomic/Icon/Icon';
import Typography from '../../../components/atomic/Typography';
import { getT } from '../../../utils/getTranslation';
import { ScreenType } from '../../../types';

interface IProps {
	currentScreen: ScreenType;
	headerText?: string,
	hideWidget: () => void,
	goOneScreenBack?: () => void,
	subtextKey: string,
}

const WidgetStartHeader = (props: IProps) => {
	const {
		currentScreen,
		headerText,
		hideWidget,
		goOneScreenBack,
		subtextKey,
	} = props;

	return (
		<StyledWidgetStartHeader>
			<StyledWidgetTitle>
				{currentScreen !== 'chooseChannels' && goOneScreenBack && (
					<Icon
						name="arrowLeft"
						className="arrowLeft"
						onClick={goOneScreenBack}
					/>
				)}
				<div className="titleTexts">
					<Typography
						variant="h3"
					>
						{headerText || getT('welcome')}
					</Typography>
					{subtextKey && (
						<Typography
							className="subtext"
							variant="button1"
						>
							{getT(subtextKey)}
						</Typography>
					)}
				</div>
			</StyledWidgetTitle>

			<Icon
				onClick={hideWidget ? () => hideWidget() : undefined}
				name="chevronDownThin"
				className="closeIcon"
			/>
		</StyledWidgetStartHeader>
	);
};

export default WidgetStartHeader;