import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	useRef,
	useCallback,
} from 'react';
import Cookies from 'universal-cookie';

import { getChatHistory, getOperatorOnlineStatus } from './api';
import { CHAT_EVENTS_TO_SHOW_IN_WIDGET } from './constants';
import { useWidget } from './WidgetProvider';
import { storageAvailable } from './utils';
import { IChatEventBrief, ILiveChatContext } from './types';

interface IProps {
	children: React.ReactNode,
}

const LiveChatContext = createContext<ILiveChatContext | null>(null);

function getLastMessageIdFromLocalStorage(chatStartedOrigin: string) {
	if (storageAvailable()) {
		// fix cookies
		const lastMessageIdSeenOld = localStorage.getItem('lastMessageIdSeen');
		if (lastMessageIdSeenOld) {
			localStorage.setItem(`lastMessageIdSeen-${chatStartedOrigin}`, lastMessageIdSeenOld);
			localStorage.removeItem('lastMessageIdSeen');
		}

		const lastMessageIdFromLS = lastMessageIdSeenOld || localStorage.getItem(`lastMessageIdSeen-${chatStartedOrigin}`);

		if (lastMessageIdFromLS) {
			return lastMessageIdFromLS;
		}
	}
	return null;
}

function getSoundSettingFromLS(chatStartedOrigin: string) {
	if (storageAvailable()) {
		// fix cookies
		const cookies = new Cookies();
		const soundSettingOld = cookies.get('soundSetting');
		if (soundSettingOld) {
			localStorage.setItem(`soundSetting-${chatStartedOrigin}`, soundSettingOld);
			cookies.remove('soundSetting');
		}

		const soundSetting = soundSettingOld || localStorage.getItem(`soundSetting-${chatStartedOrigin}`);
		if (soundSetting === undefined) {
			localStorage.setItem(`soundSetting-${chatStartedOrigin}`, 'true');
		}

		return soundSetting !== 'false';
	}
	return false;
}

const LiveChatProvider = (props: IProps) => {
	const { children } = props;

	const { contactUid, license, chatStartedOrigin } = useWidget();

	const [history, setHistory] = useState<IChatEventBrief[]>([]);
	const [totalHistoryCount, setTotalHistoryCount] = useState<number>(0);
	const [currentHistoryCount, setCurrentHistoryCount] = useState<number>(0);
	const [chatStarted, setChatStarted] = useState(false);
	const [chatId, setChatId] = useState<number>(0);
	const [chatStatus, setChatStatus] = useState('');
	const [operatorName, setOperatorName] = useState('');
	const [operatorOnline, setOperatorOnline] = useState(false);
	const [eventJustSent, setEventJustSent] = useState('');
	const [eventJustReceived, setEventJustReceived] = useState('');
	const [isSoundOn, setIsSoundOn] = useState(getSoundSettingFromLS(chatStartedOrigin));
	const [isAllMessagesSeen, setIsAllMessagesSeen] = useState(true);

	const preChatFormValues = useRef<Record<string, string> | null>(null);

	const isInstantAutoMessageSent = useRef<boolean>(false);

	const onSetIsAllMessagesSeen = useCallback(
		(history: IChatEventBrief[], isAllSeen: boolean) => {
			const historyWithMessagesFromOperator = history.filter((event) => event.type === 'operator_message');

			if (!historyWithMessagesFromOperator?.length) {
				setIsAllMessagesSeen(true);
			} else {
				const lastMessageFromOperator = historyWithMessagesFromOperator[
					historyWithMessagesFromOperator.length - 1
				];

				const lastMessageIdFromLS = getLastMessageIdFromLocalStorage(chatStartedOrigin);
				const isMessagesSeen = isAllSeen
					|| lastMessageFromOperator?.id === +(lastMessageIdFromLS || 0);

				setIsAllMessagesSeen(isMessagesSeen);

				if (isMessagesSeen && lastMessageFromOperator?.id && storageAvailable()) {
					localStorage.setItem(`lastMessageIdSeen-${chatStartedOrigin}`, lastMessageFromOperator.id.toString());
				}
			}
		},
		[chatStartedOrigin],
	);

	const onToggleSounds = () => {
		setIsSoundOn((prev) => {
			if (storageAvailable()) {
				localStorage.setItem(`soundSetting-${chatStartedOrigin}`, JSON.stringify(!prev));
			}

			return !prev;
		});
	};

	const isChatClosed = !!history.length && chatStatus === 'closed';
	const isChatClosedTheLatestEvent = isChatClosed && history[history.length - 1].type === 'chat_closed';
	const isChatNew = !history.length || chatStatus === 'new';

	useEffect(() => {
		if (license && contactUid) {
			getChatHistory(license, contactUid)
				.then((res) => {
					if (res && res.items) {
						const itemsFiltered = res.items.filter(
							(item) => CHAT_EVENTS_TO_SHOW_IN_WIDGET.includes(item.type),
						);

						setCurrentHistoryCount(res.items.length || 0);
						const newHistory = itemsFiltered.reverse();
						setHistory(newHistory);
						setTotalHistoryCount(res.total);
						onSetIsAllMessagesSeen(newHistory, false);
					} else {
						setCurrentHistoryCount(0);
					}
				})
				.catch(() => {
					setCurrentHistoryCount(0);
				});

			getOperatorOnlineStatus(license, contactUid).then((res) => {
				if (res?.length) {
					setOperatorOnline(true);
				}
			});
		}
	}, [contactUid, license]);

	useEffect(() => {
		if (!chatStatus && history.length) {
			setChatStatus(history[0]?.chat_status);
		}
	}, [chatStatus, history]);

	useEffect(() => {
		if (!operatorName && history.length) {
			const operatorName = [...history].reverse().find((item) => item.operator_name)?.operator_name;
			setOperatorName(operatorName || '');
		}
	}, [history, operatorName]);

	useEffect(() => {
		if (!chatId && history.length) {
			setChatId(history[0].chat_id);
		}
	}, [chatId, history]);

	useEffect(() => {
		if (isChatClosedTheLatestEvent) {
			// @ts-ignore
			setHistory((prev) => [...prev, { type: 'show_rating_options' }]);
		}

		if (isChatNew) {
			setHistory((prev) => prev.filter((item) => item.type !== 'show_rating_options'));
		}
	}, [isChatClosedTheLatestEvent, isChatNew]);

	const setHistoryEvent = (event: IChatEventBrief) => {
		if (event.operator_name) {
			setOperatorName(event.operator_name);
		}

		if (event.type === 'operator_joined') {
			setChatStatus('open');
		} else if (event.type === 'chat_closed' || event.type === 'operator_rated') {
			setChatStatus('closed');
		}

		if (!chatId && event.chat_id) {
			setChatId(event.chat_id);
		}

		setHistory((prev) => {
			const newEvent: IChatEventBrief = event.at ? event : { ...event, at: new Date() };

			if (prev[prev.length - 1]?.type === 'operator_typing') {
				return [
					...prev.slice(0, prev.length - 1),
					newEvent,
					prev[prev.length - 1],
				];
			}
			return [...prev, newEvent];
		});
		setCurrentHistoryCount((prev) => (prev || 0) + 1);
	};

	return (
		<LiveChatContext.Provider
			/* eslint-disable-next-line react/jsx-no-constructed-context-values */
			value={{
				history,
				totalHistoryCount,
				chatStarted,
				onSetIsAllMessagesSeen,
				preChatFormValues,
				isInstantAutoMessageSent,
				operatorName,
				operatorOnline,
				chatStatus,
				currentHistoryCount,
				eventJustSent,
				eventJustReceived,
				chatId,
				isSoundOn,
				isAllMessagesSeen,
				setOperatorOnline,
				setChatStarted,
				setChatStatus,
				setOperatorName,
				setCurrentHistoryCount,
				setEventJustSent,
				setEventJustReceived,
				onToggleSounds,
				setHistory,
				setHistoryEvent,
				setIsAllMessagesSeen,
			}}
		>
			{children}
		</LiveChatContext.Provider>
	);
};

export const useLiveChat = () => {
	const context = useContext(LiveChatContext);

	if (!context) {
		throw new Error('useLiveChatContext must be within LiveChatProvider');
	}

	return context;
};

export default LiveChatProvider;