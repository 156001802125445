import React from 'react';
import styled from 'styled-components';

interface IStyleProps {
	alignItems?: 'start' | 'end' | 'center' | 'stretch';
	justifyContent?: 'start' | 'end' | 'center' | 'stretch' | 'space-around' | 'space-between' | 'space-evenly';
	gap?: string;
	padding?: string;
}

const StyledRow = styled.div<IStyleProps>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  padding: ${({ padding }) => padding};
  gap: ${({ gap }) => gap};
`;

interface IProps extends IStyleProps {
	children: React.ReactNode,
	className?: string;
	style?: React.CSSProperties;
	onClick?: () => void;
}

const FlexRow = (props: IProps) => {
	const {
		alignItems,
		children,
		className,
		style,
		justifyContent,
		gap,
		onClick,
		padding,
	} = props;

	return (
		<StyledRow
			className={className}
			justifyContent={justifyContent}
			alignItems={alignItems}
			onClick={onClick}
			padding={padding}
			gap={gap}
			style={style}
		>
			{children}
		</StyledRow>
	);
};

export default FlexRow;

FlexRow.defaultProps = {
	alignItems: 'center',
	justifyContent: 'start',
};