import React from 'react';
import clsx from 'clsx';

import {
	StyledActions, StyledImage, StyledImageAsBackground, StyledImageWithActionsWrapper,
} from './styled';

interface IProps {
	actionIcons: boolean,
	actionIconsType?: 'permanent' | 'onHover',
	alignItems?: 'start' | 'end' | 'center' | 'stretch';
	alt?: string,
	asBackground: boolean,
	backgroundSize?: string,
	children: React.ReactNode,
	className: string,
	height: number | string,
	justifyContent?: 'start' | 'end' | 'center' | 'stretch' | 'space-around' | 'space-between' | 'space-evenly';
	imageRef: string,
	maxHeight: number | string,
	maxWidth: number | string,
	radius?: number | string,
	src?: string,
	width: number | string,
}

const Image = (props: IProps) => {
	const {
		actionIcons,
		actionIconsType,
		alignItems,
		alt,
		asBackground,
		backgroundSize,
		children,
		className,
		height,
		justifyContent,
		imageRef,
		maxHeight,
		maxWidth,
		radius,
		src,
		width,
	} = props;
	if (asBackground && actionIcons) {
		return (
			<StyledImageWithActionsWrapper
				radius={radius}
			>
				<StyledImageAsBackground
					src={src}
					height={height}
					width={width}
					className={clsx(className, 'image')}
					backgroundSize={backgroundSize}
					maxHeight={maxHeight}
					maxWidth={maxWidth}
				>
					<StyledActions
						className={clsx(actionIconsType, 'imageActions')}
						radius={radius}
					>
						{actionIcons}
					</StyledActions>
				</StyledImageAsBackground>
			</StyledImageWithActionsWrapper>
		);
	}

	if (asBackground) {
		return (
			<StyledImageAsBackground
				src={src}
				height={height}
				width={width}
				radius={radius}
				className={clsx(className, 'image')}
				backgroundSize={backgroundSize}
				maxHeight={maxHeight}
				maxWidth={maxWidth}
			/>
		);
	}

	return (
		<StyledImage
			height={height}
			width={width}
			radius={radius}
			className={className}
			alignItems={alignItems}
			justifyContent={justifyContent}
			maxHeight={maxHeight}
			maxWidth={maxWidth}
			ref={imageRef}
		>
			<img src={src} alt={alt} />
			{children}
		</StyledImage>
	);
};

Image.defaultProps = {
	actionIconsType: 'permanent',
	alignItems: 'center',
	alt: '',
	backgroundSize: 'contain',
	justifyContent: 'center',
	radius: '',
	src: '',
};

export default Image;