import { translations } from '../translations';
import { isObjectEmpty } from './isObjectEmpty';

type Lang = 'en' | 'uk' | 'ru';

export const DEFAULT_LANG: Lang = 'uk';

const currLang: Lang = navigator?.language?.slice(0, 2) as Lang;

export const tLang: Lang = ['en', 'uk', 'ru'].includes(currLang) ? currLang : DEFAULT_LANG;

export const getT = (key: string, args?: Record<string, string>): string => {
	const value = translations[tLang][key];

	if (!isObjectEmpty(args) && typeof value === 'function' && args) {
		return value(args) || '';
	}

	if (typeof value === 'string') {
		return value;
	}

	return '';
};