import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const phone = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();

	return (
		<svg width={width || 16} height={height || 16} fill="none" viewBox="0 0 16 16">
			<path
				d="M10.0113 14.8571C10.7151 15.3112 11.5538 15.509 12.3863 15.4175C13.2189 15.3259 13.9945 14.9504 14.5828 14.3543L15.097 13.8514C15.3225 13.6208 15.4487 13.3111 15.4487 12.9885C15.4487 12.666 15.3225 12.3563 15.097 12.1257L12.9142 9.96569C12.6855 9.74094 12.3777 9.615 12.057 9.615C11.7364 9.615 11.4286 9.74094 11.1999 9.96569V9.96569C10.9693 10.1912 10.6596 10.3174 10.337 10.3174C10.0145 10.3174 9.7048 10.1912 9.47418 9.96569L6.04561 6.53711C5.93123 6.4244 5.84041 6.29007 5.77841 6.14194C5.71642 5.99381 5.68449 5.83484 5.68449 5.67426C5.68449 5.51368 5.71642 5.3547 5.77841 5.20657C5.84041 5.05844 5.93123 4.92411 6.04561 4.8114V4.8114C6.27036 4.58271 6.39629 4.2749 6.39629 3.95426C6.39629 3.63362 6.27036 3.3258 6.04561 3.09711L3.87418 0.925685C3.64356 0.700217 3.33385 0.573975 3.01132 0.573975C2.6888 0.573975 2.37909 0.700217 2.14846 0.925685L1.64561 1.43997C1.04943 2.02825 0.67399 2.80386 0.582411 3.63639C0.490833 4.46892 0.688703 5.3076 1.14275 6.0114C3.50852 9.49795 6.51868 12.5004 10.0113 14.8571V14.8571Z"
				stroke={fill || theme.color.general.black}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};