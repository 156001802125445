import { createGlobalStyle } from 'styled-components';
import { getCssFromTheme } from './utils/css';

const GlobalStyle = createGlobalStyle`
  #skibble-widget-root {    
    position:relative;
    display: flex;
    flex-direction: column;
    ${({ theme }) => `height: ${theme.iframeType === 'widget' ? '100%' : 0};`}
  }

  #skibble-callback-root {
    position:relative;
    ${({ theme }) => `height: ${theme.iframeType === 'callback' ? '100%' : 0};`}
  }

  @keyframes pulse {

    0% {
      transform: scale(0.75);
      }

    70% {
      transform: scale(1);
      }

    100% {
      transform: scale(0.75);
      }
  }
  
  /* ReactTexty styles */

  [data-texty] {
    display: block !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  [data-texty-tooltip] {
    max-width: 60vw;
    padding: 10px 12px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.color.general.lighter};
    color: ${({ theme }) => theme.color.general.black};
    font-size: 12px;
    text-align: center;
    line-height: 15px;
    word-break: break-word;

    span {
      color: ${({ theme }) => theme.color.general.white};
    }
  }

  [data-texty-arrow] {
    border-color: ${({ theme }) => theme.color.general.black} transparent transparent;
  }

  [data-tooltip]::before {
    /* needed - do not touch */
    content: attr(data-tooltip);
    position: absolute;
    opacity: 0;
    z-index: 50000;

    /* customizable */
    transition: all 0.15s ease;
    padding: 10px;
    border-radius: 10px;
  }

  [data-tooltip]:not([data-tooltip-persistent])::before {
    pointer-events: none;
  }

  [data-tooltip]:hover::before {
    /* needed - do not touch */
    opacity: 1;

    /* customizable */
    background: ${({ theme }) => theme.color.general.lighter};

    .table & {
      margin-bottom: 0;
    }
  }

  .displayNone {
    display: none !important;
  }

  body {
    overflow-x: hidden;
    font-family: ${({ theme }) => theme.font.family.primary};
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html, body {
    width:100%;
    height:100%;
    margin:0;
    padding: 0;
  }

  button {
    padding: 0;
    border: none;
    color: inherit;
    cursor: pointer;
  }

  button, input, optgroup, select, textarea {
    font-family: inherit;
  }

  figure {
    margin: 0;
  }

  input::-moz-focus-inner {
    margin: 0;
    padding: 0;
    border: 0;
  }

  ul, ol, dd {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  h1 {
    ${({ theme }) => getCssFromTheme(theme, 'typography', 'h1')};
  }

  h2 {
    ${({ theme }) => getCssFromTheme(theme, 'typography', 'h2')};
  }

  h3 {
    ${({ theme }) => getCssFromTheme(theme, 'typography', 'h3')};
  }

  h4 {
    ${({ theme }) => getCssFromTheme(theme, 'typography', 'h4')};
  }

  p {
    margin: 0;
  }

  input {
    margin: 0;
  }

  fieldset {
    margin: 0;
    padding: 0;
    border-width: 0;
  }

  :focus {
    outline: 0;
    outline-offset: 0;
  }

  *, ::after, ::before {
    box-sizing: border-box;
  }

  .link.noStyles {
    color: ${({ theme }) => theme.color.secondary.main};
    text-decoration: none;
  }

  /* scrollbar */

  ::-webkit-scrollbar {
    width: 0;
  }

  /* Handle */

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${({ theme }) => theme.color.general.lighter};
  }

  /* Handle on hover */

  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.color.general.light};
  }
`;

export default GlobalStyle;