import React from 'react';

import clsx from 'clsx';
import { useTheme } from 'styled-components';
import { StyledUserpic } from './styled';
import Icon from '../Icon';
import emptyUserpic from '../../../assets/emptyUserpic.png';
import { NameTypeIcon } from '../../../types';

interface IProps {
	height: number,
	src?: string,
	operatorOnline: boolean,
	iconName?: NameTypeIcon,
	width: number | string,
	className?: string,
	iconWidth?: number | string,
	iconHeight?: number,
}

const Userpic = (props: IProps) => {
	const {
		height,
		src,
		operatorOnline,
		iconName,
		width,
		className,
		iconWidth,
		iconHeight,
	} = props;

	const theme = useTheme();

	const stateColor = {
		online: theme.color.status.success,
		offline: theme.color.status.warning,
		idle: theme.color.status.inactive,
	};

	return (
		<StyledUserpic
			src={src || emptyUserpic}
			height={height}
			width={width}
			className={clsx(className, 'userpic')}
		>
			{(operatorOnline !== undefined || iconName) && (
				<Icon
					name={iconName || 'state'}
					className="statusIcon"
					fill={operatorOnline ? stateColor.online : stateColor.offline}
					width={iconWidth}
					height={iconHeight}
				/>
			)}
		</StyledUserpic>
	);
};

Userpic.defaultProps = {
	src: '',
};

export default Userpic;