import { css, DefaultTheme } from 'styled-components';

export const getCssFromTheme = (
	theme: DefaultTheme,
	component: string,
	type?: string,
) => {
	// @ts-ignore
	const mainObject = theme.components[component] || theme.font.size[component];
	const cssObject = type ? mainObject?.[type] : mainObject;

	if (cssObject) {
		const reducer = (accString: string, cssKey: string) => `${accString}${cssKey}: ${cssObject[cssKey]};`;

		return css`
  ${Object.keys(cssObject).reduce(reducer, '')};
    `;
	}
	return '';
};