import React from 'react';

import { StyledIsTypingIndicator } from './styled';
import { StyledChatMessage } from '../../styled';

const IsTypingIndicator = () => (
	<StyledChatMessage className="operatorMessage isTyping">
		<StyledIsTypingIndicator>
			<span />
			<span />
			<span />
		</StyledIsTypingIndicator>
	</StyledChatMessage>
);

export default IsTypingIndicator;