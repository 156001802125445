import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const folder = (props: IPropsSvgIcon) => {
	const {
		width,
		height,
		fill,
		stroke,
	} = props;

	const theme = useTheme();

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none">
			<path fill={fill || theme.color.tags.light.orange} d="M1.561 14.438a.99.99 0 0 1-.99-.99V2.55a.99.99 0 0 1 .99-.99h2.562a.99.99 0 0 1 .7.29l.906.905a.99.99 0 0 0 .7.29h8.01a.99.99 0 0 1 .991.991v9.412a.99.99 0 0 1-.99.99H1.56Z" />
			<path stroke={stroke || theme.color.primary.main} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.14" d="M.57 13.447a.99.99 0 0 0 .991.991H14.44a.99.99 0 0 0 .99-.99m-14.86 0V6.508m0 6.94v-6.94m14.86 6.94V4.035a.99.99 0 0 0-.99-.99H6.43a.99.99 0 0 1-.701-.29l-.906-.906a.99.99 0 0 0-.7-.29H1.56a.99.99 0 0 0-.99.99v3.958m14.86 6.94V6.513a.99.99 0 0 0-.991-.99H1.56a.99.99 0 0 0-.99.985" />
		</svg>
	);
};