import React, {
	useState, useRef, useEffect, useCallback,
} from 'react';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import clsx from 'clsx';
import { pick } from 'dot-object';

import styled from 'styled-components';
import UploadedItem from './UploadedItem';
import { useSendEventWebsocket } from '../hooks/useWidgetWebsocket';
import useAutosizeTextArea from '../hooks/useAutosizeTextArea';
import Typography from './atomic/Typography';
import { getT } from '../utils/getTranslation';
import Input from './atomic/Input';
import ActionButtons from './ActionButtons';
import { useLiveChat } from '../LiveChatProvider';
import { useWidget } from '../WidgetProvider';
import { SYSTEM_MESSAGES_TYPES } from '../hooks/useSystemMessagesWithTimeouts';
import { usePrevious } from '../hooks/useReact';
import {
	IFileUploadResult, IResponseChatWidgetLicense, ScreenType, TextAreaRef,
} from '../types';
import { CHECKBOX_HEIGHT, CONTACT_FORM_FOOTER_HEIGHT, PAGINATION_TABS_HEIGHT } from '../constants';
import { getIsPresentKnowledgeBase } from '../utils/utils';

interface IProps {
	auto_reply_settings?: IResponseChatWidgetLicense['auto_reply_settings'],
	currentScreen: ScreenType,
	handleSendSuccess?: () => void,
	isNonWorkingTime?: boolean,
	getIsFormValid?: (fieldToCheck?: string, value?: string) => boolean,
	messageText: string,
}

interface IMainData {
	type: string,
	data: {
		text?: string,
		files?: IFileUploadResult[],
	}
}

const StyledWrapEmoji = styled.div<{
	height: number
}>`
  position: absolute;
  bottom: 75px;
  left: 12px;

  em-emoji-picker {
    max-height: ${({ height }) => height}px;
  }
`;

const Textarea = (props: IProps) => {
	const {
		auto_reply_settings,
		currentScreen,
		handleSendSuccess,
		isNonWorkingTime,
		getIsFormValid,
		messageText,
	} = props;

	const textAreaRef = useRef<TextAreaRef>(null);
	const all_channels_instant = auto_reply_settings?.all_channels_instant;

	const { widgetSettings, knowledgeBaseResponse } = useWidget();

	const { privacy_policy, is_hide_branding } = widgetSettings?.settings || {};

	const widgetHeight: number = (window.document.querySelector('#chatWrapper')
		?.getBoundingClientRect().height || 0);

	const heightBranding = is_hide_branding ? 5 : CONTACT_FORM_FOOTER_HEIGHT;

	const heightPrivacyPolicyIsRequired = privacy_policy?.is_required
		? CHECKBOX_HEIGHT
		: 0;

	const isPresentKnowledgeBase = getIsPresentKnowledgeBase(knowledgeBaseResponse);

	const heightFromPaginationTabs = isPresentKnowledgeBase ? PAGINATION_TABS_HEIGHT : 0;

	const heightTextArea = 100;

	const bottomMargin = heightTextArea
		+ heightFromPaginationTabs
		+ heightBranding
		+ heightPrivacyPolicyIsRequired;

	const heightEmoji = widgetHeight - bottomMargin;

	const { widgetOpened, license, contactUid } = useWidget();
	const {
		setHistoryEvent,
		history,
		chatStatus,
		chatStarted,
		setChatStarted,
		eventJustSent,
		isInstantAutoMessageSent,
	} = useLiveChat();

	const [message, setMessage] = useState('');
	const [files, setFiles] = useState<IFileUploadResult[]>([]);
	const [isTyping, setIsTyping] = useState(false);

	const [openedEmoji, setOpenedEmoji] = useState(false);

	const [errorText, setErrorText] = useState('');

	const isTypingTimeout = useRef<number | undefined>();

	useEffect(() => {
		if (messageText) {
			setMessage(messageText);
		}
	}, [messageText]);

	useAutosizeTextArea(textAreaRef.current, message, 126, currentScreen === 'preChatForm' || messageText);

	useEffect(() => {
		if (widgetOpened && textAreaRef.current && (widgetOpened || !eventJustSent)) {
			textAreaRef.current?.focus();
		}
	}, [widgetOpened, currentScreen, eventJustSent]);

	const constructMessageData = (type = 'contact_message') => {
		const mainData: IMainData = {
			type: SYSTEM_MESSAGES_TYPES.includes(type) ? 'system_message' : 'contact_message',
			data: {},
		};

		if (type === 'contact_message') {
			if (message) {
				mainData.data.text = message;
			}
			if (files.length) {
				mainData.data.files = files;
			}
		}

		if (SYSTEM_MESSAGES_TYPES.includes(type)) {
			mainData.data.text = pick(type, auto_reply_settings).text;
		}

		return mainData;
	};

	const removeFileError = () => {
		setErrorText('');
	};

	const onSend = (messageType: string) => {
		if (message || files) {
			const data = constructMessageData();

			if (message === 'error') {
				// @ts-ignore
				// eslint-disable-next-line no-use-before-define
				sendMessage(data);
			} else {
				// eslint-disable-next-line no-use-before-define
				sendEventToServer(data);
			}
		}

		if (SYSTEM_MESSAGES_TYPES.includes(messageType)) {
			const data = constructMessageData(messageType);
			// eslint-disable-next-line no-use-before-define
			sendEventToServer(data);
		}

		removeFileError();
	};

	const onMessageSentSuccess = (eventInProgress: string, callback: () => void) => {
		if (eventInProgress === 'contact_message' && (message || files)) {
			// @ts-ignore
			setHistoryEvent(constructMessageData());
			setMessage('');
			setFiles([]);
			callback();
			if (handleSendSuccess) {
				handleSendSuccess();
			}
			textAreaRef.current?.focus();
			setIsTyping(false);
		}

		if (SYSTEM_MESSAGES_TYPES.includes(eventInProgress)) {
			// @ts-ignore
			setHistoryEvent(constructMessageData(eventInProgress));
			callback();
		}
	};

	const {
		sendEventToServer,
		sendMessage,
		eventSentStatus,
		eventInProgress,
		onSendEvent,
		setEventSentStatus,
		setEventInProgress,
	} = useSendEventWebsocket(onSend, onMessageSentSuccess);

	const prevEventInProgress = usePrevious(eventInProgress);

	useEffect(() => {
		if (isNonWorkingTime || all_channels_instant?.first_message?.status !== 'active') {
			return;
		}

		// all these checks are needed to understand if it is the first message from contact in the chat
		// (not a system message).
		// chat can be fresh or closed and then opened again
		const historyWithoutSystemMsgs = history.filter((item, i) => !(item.type === 'system_message' && (i === history.length - 2 || i === history.length - 3)));

		if (
			!isInstantAutoMessageSent.current
      && chatStatus === 'new'
      && history[history.length - 1]?.type === 'contact_message'
      && (history.length === 1
        || (history[0].type === 'system_message' && history.length === 2)
        || (history[0].type === 'system_message'
          && history[1].type === 'system_message'
          && history.length === 3)
        || ['chat_closed', 'operator_rated', 'show_rating_options'].includes(historyWithoutSystemMsgs[historyWithoutSystemMsgs.length - 2]?.type))
		) {
			isInstantAutoMessageSent.current = true;
			onSendEvent('all_channels_instant.first_message')();
		}
	}, [history?.length]);

	useEffect(() => {
		if (
			all_channels_instant?.non_working_hours?.status === 'active'
      && isNonWorkingTime
      && !isInstantAutoMessageSent.current
      && eventJustSent === 'contact_message'
		) {
			isInstantAutoMessageSent.current = true;
			onSendEvent('all_channels_instant.non_working_hours')();
		}
	}, [eventJustSent]);

	useEffect(() => {
		if (
			all_channels_instant?.second_message?.status === 'active'
      && prevEventInProgress === 'all_channels_instant.first_message'
      && !eventInProgress
		) {
			onSendEvent('all_channels_instant.second_message')();
		}
	}, [prevEventInProgress, eventInProgress, eventSentStatus]);

	useEffect(() => {
		if (!chatStarted && history.length && chatStatus && chatStatus !== 'closed') {
			setChatStarted(true);
		}
	}, [eventSentStatus, chatStatus, chatStarted, history.length, setChatStarted]);

	const inProgress = eventSentStatus === 'inProgress'
    || eventInProgress === 'chat_started'
    || eventInProgress === 'firstUpload'
    || eventSentStatus === 'error';

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setMessage(e.currentTarget.value);
		removeFileError();
	};

	const onSendClick = useCallback(() => {
		let isFormValid = true;
		if (getIsFormValid) {
			isFormValid = getIsFormValid();
		}

		if (message || files.length) {
			if (!isFormValid) {
				return;
			}

			onSendEvent('contact_message')();
		}
	}, [getIsFormValid, message, files, onSendEvent]);

	useEffect(() => {
		if (eventInProgress === 'chat_started' && !eventSentStatus) {
			onSendClick();
		}
	}, [
		license,
		contactUid,
		eventInProgress,
		eventSentStatus,
		onSendEvent,
		setEventInProgress,
		setEventSentStatus,
	]);

	useEffect(() => {
		if (eventInProgress === 'firstUpload' && !eventSentStatus) {
			onSendClick();
		}
	}, [eventInProgress, eventSentStatus, onSendClick]);

	useEffect(() => {
		if (!isTyping && chatStatus === 'open') {
			sendEventToServer({ type: 'contact_not_typing', contact_id: contactUid });
			clearTimeout(isTypingTimeout.current);
		}

		if (isTyping) {
			sendEventToServer({ type: 'contact_typing', contact_id: contactUid });
		}
	}, [isTyping, contactUid, chatStatus]);

	const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault();
			onSendClick();
		} else {
			if (!isTyping && chatStatus === 'open') {
				setIsTyping(true);
			}

			if (isTypingTimeout.current) {
				clearTimeout(isTypingTimeout.current);
			}

			if (chatStatus === 'open') {
				// @ts-ignore
				isTypingTimeout.current = setTimeout(() => {
					setIsTyping(false);
				}, 5000);
			}
		}
	};

	const handleBlur = () => {
		if (isTyping) {
			setIsTyping(false);
		}
	};

	const onEmojiClick = (e: React.FormEvent) => {
		e?.stopPropagation();

		removeFileError();
		setOpenedEmoji((prev) => !prev);
	};

	const deleteFile = () => {
		setErrorText('');
		setFiles((prev) => prev.filter((v, i) => i !== 0));
	};

	const onEmojiSelect = (emoji: { native: string }) => {
		const cursorPlace = textAreaRef.current.selectionStart;
		const messageStart = message.slice(0, cursorPlace);
		const messageEnd = message.slice(cursorPlace);
		const text = messageStart + emoji.native + messageEnd;
		setMessage(text);
		setOpenedEmoji((prev) => !prev);
		textAreaRef.current.focus();
	};

	useEffect(() => {
		if (files.length > 1) {
			setErrorText(getT('too-many-files'));

			setFiles((prev) => prev.filter((v, i) => i === 0));
		}
	}, [files.length]);

	return (
		<>
			<Input
				id="liveChatTextarea"
				disabled={inProgress}
				// @ts-ignore
				error={eventSentStatus === 'error' && !!(message || files?.length)}
				rows={1}
				multiline
				fullWidth
				placeholder={getT('enterYourMessage')}
				insideButtons={(
					<ActionButtons
						inProgress={inProgress}
						onEmojiClick={onEmojiClick}
						onSend={onSendClick}
						setFiles={setFiles}
					/>
				)}
				onChange={handleChange}
				onKeyDown={handleKeyDown}
				onBlur={handleBlur}
				value={message}
				textAreaRef={textAreaRef}
				className={clsx(files.length && 'hasItem')}
			>
				{errorText ? (
					<Typography
						text={errorText}
						variant="input"
						textAlign="center"
						className="errorText"
					/>
				) : null}
			</Input>
			<UploadedItem file={files[0]} deleteFile={deleteFile} />

			<div id="emoji-picker">
				{openedEmoji && (
					<StyledWrapEmoji height={heightEmoji}>
						<Picker
							data={data}
							onEmojiSelect={onEmojiSelect}
							onClickOutside={onEmojiClick}
							perLine={8}
						/>
					</StyledWrapEmoji>
				)}
			</div>
		</>
	);
};

export default Textarea;