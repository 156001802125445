import React, { Dispatch, SetStateAction } from 'react';

import { StyledScreenWrapper } from '../WidgetButtonContainer/styled';
import WidgetStartHeader from './components/WidgetStartHeader';
import Channels from './components/Channels';
import WidgetFooter from './components/WidgetFooter';
import { IResponseChatWidgetLicense, ScreenType } from '../../types';
import PaginationTabs from '../../components/PaginationTabs';

interface IProps {
	className: string,
	screens: ScreenType[],
	currentScreen: ScreenType,
	setCurrentScreen: Dispatch<SetStateAction<ScreenType>>
	channels: IResponseChatWidgetLicense['channels'],
	hideWidget: () => void,
	is_hide_branding: boolean,
	goOneScreenForward: () => void,
	offlineText: string,
	setIsChannelButtonClicked: Dispatch<SetStateAction<string>>,
}

const ChooseChannels = (props: IProps) => {
	const {
		screens,
		currentScreen,
		setCurrentScreen,
		className,
		channels,
		hideWidget,
		is_hide_branding,
		goOneScreenForward,
		offlineText,
		setIsChannelButtonClicked,
	} = props;

	return (
		<StyledScreenWrapper className={className}>
			<WidgetStartHeader
				hideWidget={hideWidget}
				subtextKey={offlineText || 'quickWayToContact'}
				currentScreen="chooseChannels"
			/>

			<Channels
				channels={channels}
				goOneScreenForward={goOneScreenForward}
				setIsChannelButtonClicked={setIsChannelButtonClicked}
			/>
			<PaginationTabs
				screens={screens}
				currentScreen={currentScreen}
				setCurrentScreen={setCurrentScreen}
			/>
			{!is_hide_branding && <WidgetFooter />}
		</StyledScreenWrapper>
	);
};

export default ChooseChannels;