import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const disableSounds = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();

	return (
		<svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none">
			<path
				d="M0.572266 15.4294L15.4294 0.572266"
				stroke={fill || theme.color.general.black}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.14425 5.71484H3.42997C3.12686 5.71484 2.83617 5.83525 2.62184 6.04958C2.40752 6.26391 2.28711 6.5546 2.28711 6.8577V9.14342C2.28711 9.44652 2.40752 9.73721 2.62184 9.95154C2.83617 10.1659 3.12686 10.2863 3.42997 10.2863H5.14425V5.71484Z"
				stroke={fill || theme.color.general.black}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.4302 4.57239V3.42953C11.4296 3.21848 11.3705 3.01173 11.2596 2.8322C11.1486 2.65266 10.9901 2.50736 10.8017 2.41239C10.62 2.32561 10.4186 2.28837 10.218 2.30442C10.0173 2.32048 9.82438 2.38926 9.65882 2.50381L5.14453 5.71524"
				stroke={fill || theme.color.general.black}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.62305 12.0686L9.61162 13.4971C9.77718 13.6117 9.97007 13.6805 10.1708 13.6965C10.3714 13.7126 10.5728 13.6753 10.7545 13.5886C10.9515 13.5001 11.1194 13.3576 11.2387 13.1775C11.3581 12.9975 11.424 12.7874 11.4288 12.5714V8"
				stroke={fill || theme.color.general.black}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.14453 10.2871L5.47596 10.5271"
				stroke={fill || theme.color.general.black}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};