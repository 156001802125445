import styled from 'styled-components';

import { getCssFromTheme } from '../../../utils/css';
import { IStyledProps } from '../../../types';

export const StyledTypography = styled.span<IStyledProps>`
  ${({ theme, variant }) => getCssFromTheme(theme, 'typography', variant)};
  
  display: ${({ as }) => (as === 'div' ? 'block' : 'inline-block')};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  font-family: ${({ fontFamily }) => fontFamily};
  text-align: ${({ textAlign }) => textAlign};
  text-transform: ${({ $textTransform }) => $textTransform};
  text-decoration: ${({ $textDecoration }) => $textDecoration};
  line-height: ${({ lineHeight }) => lineHeight};
  margin-block: 0 0;
`;