import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const typeSkibbleWithNotifications = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg
			id="Layer_2"
			data-name="Layer 2"
			width={width || 59}
			height={height || 59}
			viewBox="0 0 59 59"
			fill="none"
		>
			<defs>
				<style>
					{`.cls-1 {
              fill: ${theme.color.status.warning};
            }
  
              .cls-2 {
              fill: ${fill || theme.color.primary.main};
            }
  
              .cls-2, .cls-3 {
              fill-rule: evenodd;
            }
  
              .cls-3 {
              fill: ${theme.color.general.white};
            }`}
				</style>
			</defs>
			<g id="Layer_1-2" data-name="Layer 1">
				<circle className="cls-1" cx="51" cy="8" r="8" />
				<path
					className="cls-2"
					d="m55.35,18.32c-1.34.56-2.81.88-4.35.88-6.19,0-11.2-5.01-11.2-11.2,0-1.54.31-3.01.88-4.35-9.61-.99-19.31-.85-28.9.42C6.19,4.8,1.8,9.19,1.06,14.77-.35,25.54-.35,36.46,1.06,47.23c.73,5.58,5.13,9.98,10.71,10.71,10.77,1.42,21.68,1.42,32.45,0,5.58-.73,9.98-5.13,10.71-10.71,1.26-9.59,1.4-19.29.42-28.9Z"
				/>
				<path
					className="cls-3"
					d="m9.91,31c0-9.99,8.1-18.09,18.09-18.09s18.09,8.1,18.09,18.09-8.1,18.09-18.09,18.09-18.09-8.1-18.09-18.09ZM28,14.91c-8.88,0-16.09,7.2-16.09,16.09s7.2,16.09,16.09,16.09,16.09-7.2,16.09-16.09-7.2-16.09-16.09-16.09Z"
				/>
				<path
					className="cls-3"
					d="m35.48,24.49c2.97.82,4.61,3.29,4.79,6.34h-3.25c-.27-1.33-1.12-2.31-2.38-2.66-2.23-.62-3.46,1.36-4.61,3.19-.06.1-.12.19-.18.29l-.54.84c-1.43,2.21-4.02,6.21-8.79,4.88-3.24-.9-4.72-3.41-4.78-6.53h3.23c.18,1.34.9,2.43,2.39,2.85,2.74.76,4.18-1.52,5.57-3.74l.5-.83c1.74-2.76,4.1-5.72,8.06-4.62h0Z"
				/>
			</g>
		</svg>
	);
};