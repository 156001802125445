import React from 'react';
import clsx from 'clsx';

import { StyledLabel } from './styled';

interface IProps {
	children: React.ReactNode,
	as: string,
	className: string,
	color: string,
	icon: React.ReactNode,
	margin: string,
	onClick: () => void;
	isUppercase:boolean,
	text: string,
	sizeType?: 'small' | 'medium' | 'big',
}

const Label = (props: IProps) => {
	const {
		as,
		children,
		className,
		color,
		icon,
		margin,
		onClick,
		isUppercase,
		text,
		sizeType,
	} = props;

	return (
		<StyledLabel
			as={as}
			color={color}
			sizeType={sizeType}
			margin={margin}
			onClick={onClick}
			className={clsx(className, 'label')}
			$isUppercase={isUppercase}
		>
			{icon}
			{children || text}
		</StyledLabel>
	);
};

export default Label;

Label.defaultProps = {
	sizeType: 'big',
};