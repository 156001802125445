import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const like = ({ width, height, stroke }: IPropsSvgIcon) => {
	const theme = useTheme();

	return (
		<svg width={width || 22} height={height || 20} fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.7368 10.9591c.5251 0 1.0008.2131 1.3451.5575.3444.3443.5575.82.5575 1.345 0 1.0509-.8517 1.9026-1.9026 1.9026m0-3.8051h-1.9025m1.9025 0a1.8964 1.8964 0 0 0 1.3451-.5575 1.8959 1.8959 0 0 0 .5568-1.3907c-.0241-1.0388-.9005-1.8569-1.9406-1.8569h-4.4639V3.9446c0-1.3906-1.1273-2.5178-2.5179-2.5178-.7109 0-1.2872.5763-1.2872 1.2872v1.982c0 1.1662-.4997 2.2874-1.3984 3.0307-.6024.4984-1.442.886-2.597.886v7.8003s1.583 2.1562 4.8198 2.1562h7.4833a1.8967 1.8967 0 0 0 1.3451-.5574c.3444-.3444.5575-.82.5575-1.3451 0-1.0509-.8517-1.9025-1.9026-1.9025m0 0h-1.9025M1.3603 7.6224h5.0735v10.9468H1.3603V7.6224Z" stroke={stroke || theme.color.general.black} strokeWidth="1.14" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" /></svg>
	);
};