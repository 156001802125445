import React from 'react';
import clsx from 'clsx';
import { useTheme } from 'styled-components';

import {
	StyledButtonsRow, StyledErrorLabel, StyledInput, StyledLabel, StyledWrapper,
} from './styled';
import { isObjectEmpty } from '../../../utils';
import Icon from '../Icon';
import { IProps as IIconProps } from '../Icon/Icon';
import { TextAreaRef } from '../../../types';

interface IProps {
	children?: React.ReactNode,
	id?: string,
	rows?: number,
	className?: string,
	disabled?: boolean,
	error?: string | boolean,
	field?: {
		isUppercase: boolean,
	},
	fullWidth?: boolean,
	iconLeftProps?: IIconProps,
	iconRightProps?: IIconProps,
	insideButtons?: React.ReactNode,
	isOneNumberInput?: boolean,
	label?: string,
	labelType?: 'top' | 'border',
	multiline?: boolean,
	name?: string,
	onBlur?: () => void,
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
	onEnter?: (val: string) => void,
	onSubmit?: (val: string) => void,
	placeholder?: string,
	placeholderColor?: string,
	required?: boolean,
	submitByEnterPressed?: boolean,
	success?: boolean,
	type?: 'text' | 'password' | 'number' | 'richText',
	value: string,
	withDebounce?: boolean,
	withQuantityButtons?: boolean,
	textAreaRef?: TextAreaRef,
	withoutBorder?: boolean,
	withoutValidation?: boolean,
	onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void,
}

const Input = (props: IProps) => {
	const {
		id,
		rows,
		children,
		className,
		disabled,
		error,
		field,
		textAreaRef,
		fullWidth,
		iconLeftProps,
		iconRightProps,
		insideButtons,
		isOneNumberInput,
		onKeyDown,
		label,
		labelType,
		multiline,
		name,
		onBlur,
		onChange,
		onEnter,
		onSubmit,
		placeholder,
		placeholderColor,
		required,
		submitByEnterPressed = true,
		success,
		type,
		value,
		withDebounce,
		withQuantityButtons,
		withoutBorder,
		withoutValidation,
	} = props;

	const theme = useTheme();

	const onChangeState = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (onChange && !withDebounce) {
			onChange(e);
		}
	};

	const handleKeypress = (e: React.KeyboardEvent<HTMLElement>) => {
		if (onKeyDown) {
			onKeyDown(e);
		} else if (!disabled && submitByEnterPressed && (onEnter || onSubmit) && e.key === 'Enter') {
			if (onSubmit) {
				// @ts-ignore
				onSubmit(e.target.value);
			}
			if (onEnter) {
				// @ts-ignore
				onEnter(e.target.value);
			}
		}
	};

	const hasLeftIcon = !isObjectEmpty(iconLeftProps);
	const hasRightIcon = !isObjectEmpty(iconRightProps);

	const getInputField = () => {
		const classes = clsx(
			!!error && 'hasError',
			success && 'success',
			hasLeftIcon && 'hasLeftIcon',
			hasRightIcon && 'hasRightIcon',
			multiline && 'multiline',
			withQuantityButtons && 'withQuantityButtons',
			isOneNumberInput && 'isOneNumberInput',
		);

		return (
			<StyledInput
				onKeyDown={handleKeypress}
				as={(multiline && 'textarea') || ''}
				name={name}
				disabled={disabled}
				value={value}
				onBlur={onBlur}
				onChange={onChangeState}
				placeholder={placeholder}
				placeholderColor={placeholderColor}
				type={type}
				className={classes}
				id={id}
				rows={rows}
				ref={textAreaRef}
			/>
		);
	};

	const getLeftFillColor = () => {
		if (disabled) return theme.color.general.lighter;
		if (error) return theme.color.status.error;
		return theme.color.general.light;
	};

	const getRightFillColor = () => {
		if (disabled) return theme.color.general.lighter;
		if (error) return theme.color.status.error;
		return theme.color.general.black;
	};

	return (
		<>
			<StyledWrapper
				fullWidth={fullWidth}
				className={clsx(
					'inputWrapper',
					disabled && 'disabled',
					!!error && 'hasError',
					success && 'success',
					className,
					withoutValidation && 'withoutValidation',
					insideButtons && 'withInsideButtons',
					withoutBorder && 'withoutBorder',
				)}
			>
				{label && labelType === 'border' && (
					<StyledLabel
						className={clsx(required && 'required', 'label', 'border')}
						isUppercase={field?.isUppercase}
					>
						{label?.toLowerCase()}
					</StyledLabel>
				)}

				{!!iconLeftProps && (
					<Icon
						width={16}
						height={16}
						className="leftIcon"
						fill={getLeftFillColor()}
						/* eslint-disable-next-line react/jsx-props-no-spreading */
						{...iconLeftProps}
					/>
				)}

				{getInputField()}

				{insideButtons && <StyledButtonsRow className="buttonsRow">{insideButtons}</StyledButtonsRow>}

				{!!iconRightProps && (
					<Icon
						size={15}
						className="rightIcon"
						fill={getRightFillColor()}
						/* eslint-disable-next-line react/jsx-props-no-spreading */
						{...iconRightProps}
					/>
				)}

				{children}
			</StyledWrapper>
			{!!error && typeof error === 'string' && (
				<StyledErrorLabel icon={<Icon name="inputError" size={13} />} className="error">
					{error}
				</StyledErrorLabel>
			)}
		</>
	);
};

Input.defaultProps = {
	error: '',
	isOneNumberInput: true,
	labelType: 'border',
	type: 'text',
	withQuantityButtons: false,
};

export default Input;