import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

const resizeIn = ({ width, height, fill }: IPropsSvgIcon) => {
	const theme = useTheme();

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width || 20}
			height={height || 20}
			fill="none"
			viewBox="0 0 20 20"
		>
			<path
				stroke={fill || theme.color.general.white}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.143"
				d="M8.287 8.286 2.572 2.57M3.73 8.3h4.572V3.728m3.414 7.986 5.714 5.714m-1.148-5.72h-4.572v4.572"
			/>
		</svg>
	);
};

export default resizeIn;