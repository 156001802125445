import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const typeBubbleWithNotifications = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg
			id="Layer_2"
			data-name="Layer 2"
			width={width || 59}
			height={height || 59}
			viewBox="0 0 59 59"
			fill="none"
		>
			<defs>
				<style>
					{`.cls-1, .cls-2 {
          fill: ${theme.color.general.white};
        }

          .cls-3 {
          fill: ${theme.color.status.warning};
        }

          .cls-4 {
          fill: ${fill || theme.color.primary.main};
        }

          .cls-4, .cls-2 {
          fill-rule: evenodd;
        }`}
				</style>
			</defs>
			<g id="Layer_1-2" data-name="Layer 1">
				<circle className="cls-3" cx="51" cy="8" r="8" />
				<path
					className="cls-4"
					d="m55.35,18.32c-1.34.56-2.81.88-4.35.88-6.19,0-11.2-5.01-11.2-11.2,0-1.54.31-3.01.88-4.35-9.61-.99-19.31-.85-28.9.42C6.19,4.8,1.8,9.19,1.06,14.77-.35,25.54-.35,36.46,1.06,47.23c.73,5.58,5.13,9.98,10.71,10.71,10.77,1.42,21.68,1.42,32.45,0,5.58-.73,9.98-5.13,10.71-10.71,1.26-9.59,1.4-19.29.42-28.9Z"
				/>
				<path
					className="cls-1"
					d="m20.71,32c.63,0,1.14-.51,1.14-1.14s-.51-1.14-1.14-1.14-1.14.51-1.14,1.14.51,1.14,1.14,1.14Z"
				/>
				<path
					className="cls-2"
					d="m20.71,30.71c-.08,0-.14.06-.14.14s.06.14.14.14.14-.06.14-.14-.06-.14-.14-.14Zm-2.14.14c0-1.18.96-2.14,2.14-2.14s2.14.96,2.14,2.14-.96,2.14-2.14,2.14-2.14-.96-2.14-2.14Z"
				/>
				<path
					className="cls-1"
					d="m28.14,32c.63,0,1.14-.51,1.14-1.14s-.51-1.14-1.14-1.14-1.14.51-1.14,1.14.51,1.14,1.14,1.14Z"
				/>
				<path
					className="cls-2"
					d="m28.14,30.71c-.08,0-.14.06-.14.14s.06.14.14.14.14-.06.14-.14-.06-.14-.14-.14Zm-2.14.14c0-1.18.96-2.14,2.14-2.14s2.14.96,2.14,2.14-.96,2.14-2.14,2.14-2.14-.96-2.14-2.14Z"
				/>
				<path
					className="cls-1"
					d="m35.57,32c.63,0,1.14-.51,1.14-1.14s-.51-1.14-1.14-1.14-1.14.51-1.14,1.14.51,1.14,1.14,1.14Z"
				/>
				<path
					className="cls-2"
					d="m35.57,30.71c-.08,0-.14.06-.14.14s.06.14.14.14.14-.06.14-.14-.06-.14-.14-.14Zm-2.14.14c0-1.18.96-2.14,2.14-2.14s2.14.96,2.14,2.14-.96,2.14-2.14,2.14-2.14-.96-2.14-2.14Z"
				/>
				<path
					className="cls-2"
					d="m16.71,18.14c-.34,0-.67.14-.91.38-.24.24-.38.57-.38.91v20.57c0,.11-.02.21-.05.32l-1.71,5.13,7.38-1.84c.08-.02.16-.03.24-.03h18.29c.34,0,.67-.14.91-.38s.38-.57.38-.91v-22.86c0-.34-.14-.67-.38-.91-.24-.24-.57-.38-.91-.38h-22.86Zm-2.32-1.04c.62-.62,1.45-.96,2.32-.96h22.86c.87,0,1.71.35,2.32.96.62.62.96,1.45.96,2.32v22.86c0,.87-.35,1.71-.96,2.32s-1.45.96-2.32.96h-18.16l-9.02,2.26c-.35.09-.73-.02-.98-.29s-.33-.65-.22-1l2.23-6.7v-20.41c0-.87.35-1.71.96-2.32Z"
				/>
			</g>
		</svg>
	);
};