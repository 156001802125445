import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const knowledgeBasePaginationActive = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();

	return (
		<svg width={width || 26} height={height || 26} fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M25.2999 7.7a.5.5 0 0 1 .5.5v15.6a.5.5 0 0 1-.5.5h-7.1285c-2.0962 0-3.1987 1.4214-3.311 1.5h-3.7209c-.114-.0797-1.2006-1.5-3.311-1.5H.7a.5.5 0 0 1-.5-.5V6.7a.5.5 0 0 1 .5-.5h1v16.1a.5.5 0 0 0 .5.5h5.635c2.3441 0 3.6489 1.3226 3.974 1.5h2.382c.3249-.1774 1.63-1.5 3.9739-1.5h5.635a.5.5 0 0 0 .5-.5V7.7h1Z" fill={fill || theme.color.primary.main} />
			<path d="M10.7465 21.8985C9.8355 21.5045 8.85 21.3 7.835 21.3H3.7a.5.5 0 0 1-.5-.5V3.7a.5.5 0 0 1 .5-.5h1v14.6a.5.5 0 0 0 .5.5h1.75c2.044 0 3.7159 1.6005 3.7965 3.5985ZM18.1649 4.7c-1.5886 0-3.1188.4162-4.4676 1.212.0348.3408.0527.6872.0527 1.038v15.8186a8.9065 8.9065 0 0 1 .3461-.2395c2.2332-1.4894 3.7966-1.2492 8.2028-1.2302a.4991.4991 0 0 0 .501-.4989V5.2a.5.5 0 0 0-.5-.5h-4.135Z" fill={fill || theme.color.primary.main} />
			<path d="M12.25 22.05c0-2.8948-2.3776-5.25-5.3-5.25H6.7a.5.5 0 0 1-.5-.5V.7c0-.2761.225-.502.5005-.4816C10.1532.4757 12.25 3.3967 12.25 6.95v15.1Z" fill={fill || theme.color.primary.main} />
		</svg>
	);
};