import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

const knowledgeBase = ({ width, height, fill }: IPropsSvgIcon) => {
	const theme = useTheme();

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width || 20}
			height={height || 20}
			fill="none"
			viewBox="0 0 20 20"
		>
			<path
				stroke={fill || theme.color.general.white}
				strokeMiterlimit="10"
				strokeWidth="1.14"
				d="M4.345 6.22H2.97a.5.5 0 0 0-.5.5v9.375a.5.5 0 0 0 .5.5h3.8a3.2 3.2 0 0 1 2.262.938h1.938a3.2 3.2 0 0 1 2.263-.938h3.8a.5.5 0 0 0 .5-.5V7.657a.5.5 0 0 0-.5-.5h-1.376m-5.656-.937h.294a5.016 5.016 0 0 1 2.934-.938h1.929a.5.5 0 0 1 .5.5v8.438a.5.5 0 0 1-.5.5h-1.929a5.017 5.017 0 0 0-2.934.938h-.588a5.017 5.017 0 0 0-2.934-.938H4.845a.5.5 0 0 1-.5-.5V4.845a.5.5 0 0 1 .5-.5H6.22m3.781 1.875v9.438c0-1.903-1.442-3.474-3.282-3.718-.274-.036-.499-.256-.499-.532V2.97c0-.276.225-.503.499-.467C8.559 2.746 10 4.318 10 6.22Z"
			/>
		</svg>
	);
};

export default knowledgeBase;