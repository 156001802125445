import React from 'react';

import { StyledButtonWrapper } from '../../components/styled';
import Icon from '../../components/atomic/Icon/Icon';
import { useWidget } from '../../WidgetProvider';

interface IProps {
	className: string,
}

const CallbackButton = (props: IProps) => {
	const { className } = props;
	const { setWidgetOpened, callbackOpened, widgetSettings } = useWidget();

	const button_placement = widgetSettings?.settings?.callback_button_placement;

	return (
		<StyledButtonWrapper placement={button_placement}>
			<Icon
				className={className}
				name="typeCallback"
				onClick={() => {
					setWidgetOpened(!callbackOpened, 'callback');
				}}
				alignItems="end"
				justifyContent="start"
			/>
		</StyledButtonWrapper>
	);
};

export default CallbackButton;