import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	useCallback,
	useRef,
} from 'react';
import Cookies from 'universal-cookie';
import { nanoid } from 'nanoid';

import { isValidJSON, storageAvailable } from './utils';
import { getKnowledgeBaseArticleList, getWidgetSettings } from './api';
import { useNonWorkingHours } from './hooks/useNonWorkingHours';
import {
	BOTH_BUTTONS_CONTAINER_SIZE,
	CALLBACK_FORM_HEIGHT,
	CHOOSE_CHANNELS_FOOTER_HEIGHT,
	MAIN_BUTTON_SIZE,
} from './constants';
import {
	ChatDetails,
	IframeType, IKnowledgeBaseResponse,
	IResponseChatWidgetLicense,
	IWidgetContext,
	WidgetPosition,
} from './types';

interface IProps {
	children: React.ReactNode,
}

const WidgetContext = createContext<IWidgetContext | null>(null);
const cookies = new Cookies();

const WidgetProvider = (props: IProps) => {
	const { children } = props;

	const [iframeType, setIframeType] = useState<IframeType | null>(null);
	const [license, setLicense] = useState('');
	const [chatStartedUrl, setChatStartedUrl] = useState('');
	const [chatStartedOrigin, setChatStartedOrigin] = useState('');
	const [contactUid, setContactUid] = useState('');
	const [chatDetails, setChatDetails] = useState<ChatDetails | null>(null);

	const [widgetOpened, setWidgetOpened] = useState(false);
	const [knowledgeBaseItemId, setKnowledgeBaseItemId] = useState<number | null>(null);
	const [callbackOpened, setCallbackOpened] = useState(false);
	const [isTwoButtonsOnOneSide, setIsTwoButtonsOnOneSide] = useState(false);
	const [isLargeScreen, setIsLargeScreen] = useState(false);

	const [widgetSettings, setWidgetSettings] = useState<IResponseChatWidgetLicense | null>(null);
	const [inProgress, setInProgress] = useState(true);

	const [knowledgeBaseResponse, setKnowledgeBaseResponse] = useState<
		IKnowledgeBaseResponse
	>({
		folders: [],
		articles: [],
	});

	const isSecondIframeLoaded = useRef(false);

	const changeIframeType = (type: IframeType) => {
		window.parent.postMessage(
			{
				type: 'changeIframeType',
				newIframeType: type,
			},
			chatStartedOrigin || '*',
		);
	};

	const hideWidget = useCallback(
		(iframeType: IframeType) => {
			window.parent.postMessage(
				{
					type: 'closeWidget',
					iframeType,
					width: isTwoButtonsOnOneSide && `${BOTH_BUTTONS_CONTAINER_SIZE}px`,
					isTwoButtonsOnOneSide,
				},
				chatStartedOrigin || '*',
			);
		},
		[chatStartedOrigin, isTwoButtonsOnOneSide],
	);

	const showWidget = useCallback(
		(iframeType: IframeType, height?: number) => {
			window.parent.postMessage(
				{
					type: isTwoButtonsOnOneSide ? 'openWhenButtonsOnOneSide' : 'openWidget',
					iframeType,
					height: height || 0,
				},
				chatStartedOrigin || '*',
			);
		},
		[chatStartedOrigin, isTwoButtonsOnOneSide],
	);

	const disableWidget = useCallback(() => {
		window.parent.postMessage('hideEverything', chatStartedOrigin || '*');
	}, [chatStartedOrigin]);

	const changeLiveChatScreenHeight = (height: number, width: number) => {
		window.parent.postMessage(
			{
				type: 'changeLiveChatScreenHeight',
				height,
				width,
				iframeType: isTwoButtonsOnOneSide ? 'both' : iframeType,
			},
			chatStartedOrigin || '*',
		);
	};
	const changePreLiveChatScreenHeight = (height: number, width: number) => {
		window.parent.postMessage(
			{
				type: 'changePreLiveChatScreenHeight',
				height,
				width,
				iframeType: isTwoButtonsOnOneSide ? 'both' : iframeType,
			},
			chatStartedOrigin || '*',
		);
	};

	const changeIframeWidth = (width: number) => {
		window.parent.postMessage(
			{
				type: 'changeIframeWidth',
				width,
			},
			chatStartedOrigin || '*',
		);
	};

	const loadCallbackIframe = (callback_button_placement: WidgetPosition) => {
		window.parent.postMessage(
			{ type: 'loadCallbackIframe', callback_button_placement },
			chatStartedOrigin || '*',
		);
	};

	const showWidgetStartButton = (
		button_placement: WidgetPosition,
		iframeType: IframeType,
		iframeWidth: string | number,
	) => {
		const width = iframeWidth || (isTwoButtonsOnOneSide && `${BOTH_BUTTONS_CONTAINER_SIZE}px`);
		window.parent.postMessage(
			{
				type: 'showWidgetStartButton',
				width: width ? `${width}px` : '',
				placement: button_placement,
				iframeType,
			},
			chatStartedOrigin || '*',
		);
	};

	const onSetWidgetOpened = useCallback(
		(opened: boolean, iframeType: IframeType = 'widget') => {
			const callbackHeight = iframeType === 'callback' && (!widgetSettings?.settings?.is_hide_branding
				? CALLBACK_FORM_HEIGHT + CHOOSE_CHANNELS_FOOTER_HEIGHT
				: CALLBACK_FORM_HEIGHT);

			if (opened) {
				showWidget(iframeType, callbackHeight || undefined);
			} else {
				hideWidget(iframeType);
			}

			if (iframeType === 'callback') {
				setCallbackOpened(opened);
				if (opened) {
					setWidgetOpened(false);
				}
			} else {
				setWidgetOpened(opened);
				if (opened) {
					setCallbackOpened(false);
				}
			}

			changeIframeWidth(340);
		},
		[chatStartedOrigin, hideWidget, showWidget, widgetSettings?.settings?.is_hide_branding],
	);

	useEffect(() => {
		if (chatStartedOrigin) {
			let newContactUid;
			let savedContactUid;
			// fix cookies
			if (storageAvailable()) {
				const contactUidOld = cookies.get('contactUid') || localStorage.getItem('contactUid');
				if (contactUidOld) {
					localStorage.setItem(`contactUid-${chatStartedOrigin}`, contactUidOld);
					cookies.remove('contactUid');
					localStorage.removeItem('contactUid');
				}

				savedContactUid = contactUidOld || localStorage.getItem(`contactUid-${chatStartedOrigin}`);

				if (!savedContactUid) {
					newContactUid = nanoid();
					localStorage.setItem(`contactUid-${chatStartedOrigin}`, newContactUid);
				}
			} else {
				newContactUid = nanoid();
			}

			setContactUid(savedContactUid || newContactUid);
		}
	}, [chatStartedOrigin, setContactUid]);

	useEffect(() => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const originUrl = urlParams.get('url') || '';
		const origin = urlParams.get('origin') || '';
		const license = urlParams.get('license') || '';
		const iframeType = urlParams.get('iframeType') as IframeType || null;
		setChatStartedUrl(originUrl);
		setChatStartedOrigin(`${origin}/`);
		setLicense(license);
		setIframeType(iframeType);

		if (process.env.REACT_APP_LICENCE_ID) {
			setLicense(process.env.REACT_APP_LICENCE_ID);
		}
	}, []);

	function handleMessage(event: MessageEvent) {
		// Handle the message received from the parent
		try {
			const payload = isValidJSON(event.data) && JSON.parse(event.data);

			if (payload?.action === 'sendMessage') {
				setChatDetails(payload?.details || null);
			}
		} catch (e) {
			// empty error
		}
	}

	useEffect(() => {
		window.addEventListener('message', handleMessage);

		return () => {
			window.removeEventListener('message', handleMessage);
		};
	}, []);

	const isWholeWidgetActive = widgetSettings?.status === 'active'
    && Object.values(widgetSettings?.channels)?.some((channel) => channel.status === 'active');

	const getIsWidgetButtonActive = (data: IResponseChatWidgetLicense) => (
		data?.channels
		&& Object.keys(data?.channels).some((channelKey) => (
			channelKey !== 'callback'
			// @ts-ignore
			&& data.channels[channelKey].status === 'active'
		))
	);

	const getButtonsAreOnOneSide = (data: IResponseChatWidgetLicense) => (
		data?.settings?.button_placement === data?.settings?.callback_button_placement
	);

	const getIsCallbackActive = (data: IResponseChatWidgetLicense) => (
		data?.channels?.callback?.status === 'active'
	);

	const getIsSecondIframeLoaded = (bothButtonsActive: boolean, buttonsAreOnOneSide: boolean) => (
		iframeType === 'widget' && bothButtonsActive && !buttonsAreOnOneSide
	);

	const onGetSettingsSuccessWidget = (
		data: IResponseChatWidgetLicense,
		isCallbackActive: boolean,
		isWidgetButtonActive: boolean,
		bothButtonsActive: boolean,
		buttonsAreOnOneSide: boolean,
	) => {
		if (isCallbackActive && !isWidgetButtonActive) {
			setIframeType('callback');
			changeIframeType('callback');
			showWidgetStartButton(data.settings.callback_button_placement, 'callback', MAIN_BUTTON_SIZE);
			const skibbleCallbackRoot = document.getElementById('skibble-callback-root');
			const skibbleWidgetRoot = document.getElementById('skibble-widget-root');

			if (skibbleCallbackRoot) {
				skibbleCallbackRoot.style.height = '0';
			}

			if (skibbleWidgetRoot) {
				skibbleWidgetRoot.style.height = '100%';
			}
		} else {
			showWidgetStartButton(
				data.settings.button_placement,
				'widget',
				bothButtonsActive && buttonsAreOnOneSide ? BOTH_BUTTONS_CONTAINER_SIZE : MAIN_BUTTON_SIZE,
			);
		}
	};

	const onGetSettingsSuccess = (data: IResponseChatWidgetLicense) => {
		setWidgetSettings(data);

		const isCallbackActive = getIsCallbackActive(data);
		const isWidgetButtonActive = getIsWidgetButtonActive(data);

		const bothButtonsActive = isCallbackActive && isWidgetButtonActive;
		const buttonsAreOnOneSide = getButtonsAreOnOneSide(data);

		if (bothButtonsActive && buttonsAreOnOneSide) {
			setIsTwoButtonsOnOneSide(true);
		}

		if (getIsSecondIframeLoaded(bothButtonsActive, buttonsAreOnOneSide)) {
			if (!isSecondIframeLoaded.current) {
				loadCallbackIframe(data?.settings?.callback_button_placement);
			}
			isSecondIframeLoaded.current = true;
		}

		if (iframeType === 'widget') {
			onGetSettingsSuccessWidget(
				data,
				isCallbackActive,
				isWidgetButtonActive,
				bothButtonsActive,
				buttonsAreOnOneSide,
			);
		}
	};

	useEffect(() => {
		if (license && contactUid) {
			getWidgetSettings(license)
				.then((data) => {
					onGetSettingsSuccess(data);
					setInProgress(false);
				})
				.catch(() => {
					setInProgress(false);
				});
		}
	}, [contactUid, license]);

	useEffect(() => {
		if (license) {
			getKnowledgeBaseArticleList(license)
				.then((data) => {
					setKnowledgeBaseResponse(data);
				})
				.catch(() => null);
		}
	}, [license]);

	const { timezone_region, work_hours } = widgetSettings || {};

	const isNonWorkingTime = useNonWorkingHours({ work_hours, timezone_region });

	useEffect(() => {
		if (!inProgress && !isWholeWidgetActive) {
			disableWidget();
		}
	}, [isWholeWidgetActive, inProgress, disableWidget]);

	if (!license || !isWholeWidgetActive || !iframeType || inProgress) {
		return null;
	}

	return (
		<WidgetContext.Provider
			/* eslint-disable-next-line react/jsx-no-constructed-context-values */
			value={{
				knowledgeBaseResponse,
				knowledgeBaseItemId,
				setKnowledgeBaseItemId,
				license,
				contactUid,
				changeIframeWidth,
				chatStartedOrigin,
				chatStartedUrl,
				chatDetails,
				setChatDetails,
				setWidgetOpened: onSetWidgetOpened,
				widgetOpened,
				callbackOpened,
				changeLiveChatScreenHeight,
				changePreLiveChatScreenHeight,
				iframeType,
				isLargeScreen,
				setIsLargeScreen,
				widgetSettings,
				isNonWorkingTime,
				isTwoButtonsOnOneSide,
			}}
		>
			{children}
		</WidgetContext.Provider>
	);
};

export const useWidget = () => {
	const context = useContext(WidgetContext);

	if (!context) {
		throw new Error('useWidgetContext must be within WidgetProvider');
	}

	return context;
};

export default WidgetProvider;