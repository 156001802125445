import React from 'react';
import clsx from 'clsx';

import { IconWrapper } from './styled';
import Icons from '../../../assets/icons';
import { IPropsSvgIcon, IStyledProps, NameTypeIcon } from '../../../types';

export interface IProps extends IPropsSvgIcon, IStyledProps {
	id?: string;
	title?: string;
	className?: string;
	disabled?: boolean;
	onClick?: () => void;
	style?: React.CSSProperties;
	withoutWrapper?: boolean;
	name: NameTypeIcon,
	alignItems?: string;
	justifyContent?: string;
}

const Icon: React.FC<IProps> = (props) => {
	const {
		fill,
		stroke,
		justifyContent,
		alignItems,
		margin,
		width,
		height,
		size,
		borderRadius,
		wrapperColor,
		id,
		title,
		className,
		disabled,
		onClick,
		style,
		withoutWrapper,
		name,
	} = props;

	const IconComponent = Icons[name];

	if (!IconComponent) {
		return null;
	}

	if (withoutWrapper) {
		return (
			<IconComponent
				width={width}
				height={height}
				fill={fill}
				stroke={stroke}
				wrapperColor={wrapperColor}
			/>
		);
	}

	return (
		<IconWrapper
			id={id}
			title={title}
			margin={margin}
			size={size}
			className={clsx(className, 'icon', disabled && 'disabled')}
			onClick={disabled ? undefined : onClick}
			wrapperColor={wrapperColor}
			borderRadius={borderRadius}
			style={style}
			justifyContent={justifyContent}
			alignItems={alignItems}
		>
			<IconComponent
				width={width}
				height={height}
				fill={fill}
				stroke={stroke}
				wrapperColor={wrapperColor}
			/>
		</IconWrapper>
	);
};

export default Icon;

Icon.defaultProps = {
	alignItems: 'center',
	justifyContent: 'center',
};