import React, {
	Dispatch,
	SetStateAction,
	useEffect,
	useRef,
} from 'react';
import styled from 'styled-components';
import { IKnowledgeBaseResponse, ScreenType } from '../../../types';
import Folder from './Folder';
import Article from './Article';
import EmptyList from './EmptyList';

const Wrap = styled.div`
  flex: 1;
  margin-right: 10px;
  padding: 5px 10px;
  overflow: auto;
  
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: ${({ theme }) => theme.color.general.darker};
  }
  
  &::-webkit-scrollbar-track {
    margin: 5px 0;
    border-radius: 4px;
    background: ${({ theme }) => theme.color.general.darkest}26;
  }
`;

interface IProps {
	data: IKnowledgeBaseResponse;
	search: string;
	setCurrentScreen: Dispatch<SetStateAction<ScreenType>>;
}

let timer: NodeJS.Timeout;

let cachePosition = 0;

const List = (props: IProps) => {
	const { data, search, setCurrentScreen } = props;

	const ref = useRef<HTMLDivElement>(null);

	const isEmptyData = data.articles.length === 0 && data.folders.length === 0;

	const handleScroll = () => {
		clearTimeout(timer);

		timer = setTimeout(() => {
			cachePosition = ref.current?.scrollTop || 0;
		}, 50);
	};

	useEffect(() => {
		setTimeout(() => {
			if (cachePosition && ref.current) {
				ref.current.scrollTop = cachePosition;
			}
		}, 200);
	}, [ref.current]);

	return (
		<Wrap ref={ref} onScroll={handleScroll}>
			{isEmptyData && search ? (
				<EmptyList search={search} />
			) : (
				<>
					{data.folders.map((folder) => (
						<Folder
							hasSearch={!!search}
							key={folder.id}
							id={folder.id}
							name={folder.name}
							articles={folder.articles}
							setCurrentScreen={setCurrentScreen}
						/>
					))}
					{data.articles.map((article) => (
						<Article
							key={article.id}
							id={article.id}
							title={article.title}
							setCurrentScreen={setCurrentScreen}
						/>
					))}
				</>
			)}
		</Wrap>
	);
};

export default List;