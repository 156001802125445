import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const typeBriefcaseWithNotifications = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg
			id="Layer_2"
			data-name="Layer 2"
			width={width || 59}
			height={height || 59}
			viewBox="0 0 59 59"
			fill="none"
		>
			<defs>
				<style>
					{`.cls-1 {
          fill: ${theme.color.status.warning};
        }

          .cls-2 {
          fill: ${fill || theme.color.primary.main};
        }

          .cls-2, .cls-3 {
          fill-rule: evenodd;
        }

          .cls-3 {
          fill: ${theme.color.general.white};
        }`}
				</style>
			</defs>
			<g id="Layer_1-2" data-name="Layer 1">
				<circle className="cls-1" cx="51" cy="8" r="8" />
				<path
					className="cls-2"
					d="m55.35,18.32c-1.34.56-2.81.88-4.35.88-6.19,0-11.2-5.01-11.2-11.2,0-1.54.31-3.01.88-4.35-9.61-.99-19.31-.85-28.9.42C6.19,4.8,1.8,9.19,1.06,14.77-.35,25.54-.35,36.46,1.06,47.23c.73,5.58,5.13,9.98,10.71,10.71,10.77,1.42,21.68,1.42,32.45,0,5.58-.73,9.98-5.13,10.71-10.71,1.26-9.59,1.4-19.29.42-28.9Z"
				/>
				<path
					className="cls-3"
					d="m15.43,23.29c-.71,0-1.29.58-1.29,1.29v18c0,.71.58,1.29,1.29,1.29h25.14c.71,0,1.29-.58,1.29-1.29v-18c0-.71-.58-1.29-1.29-1.29H15.43Zm-3.29,1.29c0-1.81,1.47-3.29,3.29-3.29h25.14c1.81,0,3.29,1.47,3.29,3.29v18c0,1.81-1.47,3.29-3.29,3.29H15.43c-1.81,0-3.29-1.47-3.29-3.29v-18Z"
				/>
				<path
					className="cls-3"
					d="m12.14,33.43c0-.55.45-1,1-1h29.71c.55,0,1,.45,1,1s-.45,1-1,1H13.14c-.55,0-1-.45-1-1Z"
				/>
				<path
					className="cls-3"
					d="m28,30.14c.55,0,1,.45,1,1v4.57c0,.55-.45,1-1,1s-1-.45-1-1v-4.57c0-.55.45-1,1-1Z"
				/>
				<path
					className="cls-3"
					d="m22.44,16.73c1.47-1.47,3.47-2.3,5.56-2.3s4.08.83,5.56,2.3c1.47,1.47,2.3,3.47,2.3,5.56,0,.55-.45,1-1,1s-1-.45-1-1c0-1.55-.62-3.04-1.72-4.14-1.1-1.1-2.59-1.72-4.14-1.72s-3.04.62-4.14,1.72c-1.1,1.1-1.72,2.59-1.72,4.14,0,.55-.45,1-1,1s-1-.45-1-1c0-2.08.83-4.08,2.3-5.56Z"
				/>
			</g>
		</svg>
	);
};