import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const threeDotsHollow = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 6} height={height || 16} viewBox="0 0 6 16" fill="none">
			<path
				d="M2.99944 4.00084C3.94622 4.00084 4.71373 3.23333 4.71373 2.28655C4.71373 1.33978 3.94622 0.572266 2.99944 0.572266C2.05267 0.572266 1.28516 1.33978 1.28516 2.28655C1.28516 3.23333 2.05267 4.00084 2.99944 4.00084Z"
				stroke={fill || theme.color.general.white}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.99944 9.71568C3.94622 9.71568 4.71373 8.94817 4.71373 8.0014C4.71373 7.05462 3.94622 6.28711 2.99944 6.28711C2.05267 6.28711 1.28516 7.05462 1.28516 8.0014C1.28516 8.94817 2.05267 9.71568 2.99944 9.71568Z"
				stroke={fill || theme.color.general.white}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.99944 15.4286C3.94622 15.4286 4.71373 14.6611 4.71373 13.7143C4.71373 12.7675 3.94622 12 2.99944 12C2.05267 12 1.28516 12.7675 1.28516 13.7143C1.28516 14.6611 2.05267 15.4286 2.99944 15.4286Z"
				stroke={fill || theme.color.general.white}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};