import { useEffect } from 'react';

const useAutosizeTextArea = (
	textAreaRefParam: HTMLInputElement | null,
	value: string,
	maxHeight: number,
	disableAutosize: string | boolean,
) => {
	const textAreaRef = textAreaRefParam;

	useEffect(() => {
		if (disableAutosize) {
			return;
		}

		if (textAreaRef) {
			if (!value) {
				textAreaRef.style.height = '0px';
			} else {
				textAreaRef.style.height = '0px';
				const { scrollHeight } = textAreaRef;

				if (scrollHeight <= maxHeight) {
					textAreaRef.style.height = `${scrollHeight}px`;
				} else {
					textAreaRef.style.height = `${maxHeight}px`;
				}
			}
		}
	}, [disableAutosize, textAreaRef, value]);
};

export default useAutosizeTextArea;