import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const disLike = ({ width, height, stroke }: IPropsSvgIcon) => {
	const theme = useTheme();

	return (
		<svg width={width || 22} height={height || 20} fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.2629 9.0408c-.5251 0-1.0008-.2131-1.3451-.5575a1.8966 1.8966 0 0 1-.5575-1.345c0-1.0509.8518-1.9026 1.9026-1.9026m0 3.805 1.9025.0001m-1.9025 0c-.5251 0-1.0008.2131-1.3451.5575a1.896 1.896 0 0 0-.5568 1.3907c.024 1.0388.9005 1.8569 1.9406 1.8569h4.464v3.2094c0 1.3906 1.1272 2.5178 2.5178 2.5178.7109 0 1.2872-.5763 1.2872-1.2872V15.304c0-1.1663.4997-2.2875 1.3984-3.0308.6024-.4984 1.4421-.8859 2.5969-.8859V3.5869s-1.5829-2.1562-4.8197-2.1562H3.2629c-.5251 0-1.0008.213-1.3451.5574a1.8966 1.8966 0 0 0-.5575 1.3451c0 1.0509.8518 1.9025 1.9026 1.9025m0 0h1.9025m15.474 7.1418h-5.0735V1.4307h5.0735v10.9468Z" stroke={stroke || theme.color.general.black} strokeWidth="1.14" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" /></svg>
	);
};