import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const starSign = ({
	width, height, fill, stroke,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 16} height={height || 16} fill="none" viewBox="0 0 16 16">
			<path
				d="M8.56 1.24573L10.3771 4.9143C10.4185 5.00741 10.4835 5.08807 10.5657 5.14822C10.6479 5.20838 10.7445 5.24594 10.8457 5.25716L14.8571 5.85144C14.9733 5.86637 15.0828 5.91404 15.1729 5.98888C15.2629 6.06371 15.3299 6.16263 15.3658 6.27408C15.4018 6.38553 15.4053 6.50491 15.3759 6.61828C15.3465 6.73164 15.2855 6.83431 15.2 6.9143L12.3086 9.78287C12.2348 9.85182 12.1794 9.93812 12.1475 10.0339C12.1155 10.1297 12.108 10.232 12.1257 10.3314L12.8229 14.3657C12.843 14.4816 12.8303 14.6009 12.786 14.7099C12.7418 14.819 12.6678 14.9134 12.5726 14.9825C12.4773 15.0515 12.3646 15.0925 12.2472 15.1006C12.1298 15.1088 12.0125 15.0838 11.9086 15.0286L8.29715 13.12C8.20467 13.0746 8.10302 13.051 8 13.051C7.89699 13.051 7.79534 13.0746 7.70286 13.12L4.09143 15.0286C3.98753 15.0838 3.87022 15.1088 3.75283 15.1006C3.63545 15.0925 3.52271 15.0515 3.42745 14.9825C3.33218 14.9134 3.25822 14.819 3.21397 14.7099C3.16972 14.6009 3.15696 14.4816 3.17715 14.3657L3.87429 10.2857C3.89196 10.1863 3.88448 10.084 3.85255 9.98821C3.82061 9.8924 3.76523 9.8061 3.69143 9.73716L0.765717 6.9143C0.67916 6.83212 0.618289 6.72661 0.590476 6.61054C0.562664 6.49448 0.569104 6.37284 0.609017 6.26035C0.648929 6.14787 0.720602 6.04938 0.815352 5.9768C0.910103 5.90422 1.02387 5.86068 1.14286 5.85144L5.15429 5.25716C5.25554 5.24594 5.35209 5.20838 5.43431 5.14822C5.51652 5.08807 5.58153 5.00741 5.62286 4.9143L7.44 1.24573C7.48949 1.13888 7.56851 1.04842 7.66773 0.985022C7.76696 0.921626 7.88225 0.887939 8 0.887939C8.11775 0.887939 8.23305 0.921626 8.33227 0.985022C8.4315 1.04842 8.51052 1.13888 8.56 1.24573Z"
				fill={fill || theme.color.general.white}
				stroke={stroke || '#FFB84D'}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};