import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const disableSoundsHeader = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();

	return (
		<svg width={width || 21} height={height || 20} fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.0714 17.4286 17.9286 2.5714M9.3572 17.4286h2.2857M12.4772 2.96a5.1543 5.1543 0 0 0-7.12 4.7543v2.8571M5.9286 14.5714h12a2.2861 2.2861 0 0 1-1.6163-.6694 2.2861 2.2861 0 0 1-.6694-1.6163V7.7143a5.0519 5.0519 0 0 0-.5715-2.2857" stroke={fill || theme.color.general.white} strokeWidth="1.1429" strokeLinecap="round" strokeLinejoin="round" /></svg>
	);
};