import styled from 'styled-components';
import { IStyledProps } from '../../../types';

export const StyledTextPanel = styled.div<IStyledProps>`
  position: relative;
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  padding: ${({ padding }) => padding || '10px 15px'};
  border-radius: ${({ borderRadius }) => borderRadius || '3px'};
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  text-align: ${({ textAlign }) => textAlign};

  .titleAndContent {
    width: 100%;

    .title {
      max-width: 290px;
    }

    .typography {
      color: ${({ color }) => color};
    }
  }

  &.bordered {
    border: ${({ borderColor, color }) => `1px solid ${borderColor || color}`};
  }

  &.fullWidth {
    width: 100%;
  }
`;