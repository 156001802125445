import React from 'react';

import { StyledFilesList } from './styled';
import FileItem from './FileItem';
import { IFileUploadResult } from '../types';

interface IProps {
	files: IFileUploadResult[],
	type: string,
}

const FilesList = (props: IProps) => {
	const { files, type } = props;
	return (
		<StyledFilesList className={`${type}Container`}>
			{files.map((file) => {
				if (!file?.url) {
					return null;
				}
				return <FileItem key={file.url} type={type} file={file} />;
			})}
		</StyledFilesList>
	);
};

export default FilesList;