import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const enableSounds = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();

	return (
		<svg width={width || 16} height={height || 14} viewBox="0 0 16 14" fill="none">
			<path
				d="M3.42941 4.71484H1.71512C1.41202 4.71484 1.12133 4.83525 0.907001 5.04958C0.692673 5.26391 0.572266 5.5546 0.572266 5.8577V8.14342C0.572266 8.44652 0.692673 8.73721 0.907001 8.95154C1.12133 9.16586 1.41202 9.28627 1.71512 9.28627H3.42941V4.71484Z"
				stroke={fill || theme.color.general.black}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3.42969 9.28667L7.89826 12.4981C8.06382 12.6127 8.25671 12.6814 8.45739 12.6975C8.65808 12.7136 8.85945 12.6763 9.04112 12.5895C9.23814 12.501 9.40604 12.3585 9.52538 12.1785C9.64472 11.9985 9.71061 11.7883 9.7154 11.5724V2.42953C9.72029 2.22429 9.66981 2.02153 9.56925 1.84255C9.4687 1.66357 9.3218 1.51497 9.14397 1.41239C8.96231 1.32561 8.76094 1.28837 8.56025 1.30442C8.35956 1.32048 8.16668 1.38926 8.00112 1.50381L3.42969 4.71524"
				stroke={fill || theme.color.general.black}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.2871 3.57227C15.0817 4.53293 15.4893 5.75554 15.43 7.00084C15.4128 8.41227 15.0182 9.79339 14.2871 11.0008"
				stroke={fill || theme.color.general.black}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12 5.28711C12.3973 5.76744 12.6011 6.37875 12.5714 7.0014C12.6011 7.62404 12.3973 8.23535 12 8.71568"
				stroke={fill || theme.color.general.black}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};