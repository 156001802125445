import React from 'react';
import { IPropsSvgIcon } from '../../types';

export const skibbleIcon = ({ width, height }: IPropsSvgIcon) => (
	<svg width={width || 15} height={height || 15} viewBox="0 0 15 15">
		<path
			d="M6.70325 0.410744C7.13209 -0.0555342 7.86791 -0.0555345 8.29675 0.410744L8.74564 0.898829C9.01689 1.19375 9.42886 1.31472 9.81649 1.21326L10.458 1.04535C11.0709 0.884934 11.6899 1.28275 11.7985 1.90686L11.9123 2.56015C11.981 2.9549 12.2622 3.2794 12.6432 3.40361L13.2736 3.60918C13.8759 3.80557 14.1816 4.4749 13.9356 5.05868L13.6781 5.66976C13.5225 6.03901 13.5836 6.46401 13.8369 6.77446L14.2561 7.28825C14.6566 7.77908 14.5519 8.50741 14.0294 8.86553L13.4824 9.24039C13.1518 9.4669 12.9735 9.85747 13.0187 10.2556L13.0936 10.9145C13.1652 11.5439 12.6833 12.1 12.0501 12.1188L11.3873 12.1384C10.9868 12.1502 10.6256 12.3824 10.4484 12.7418L10.1552 13.3366C9.8751 13.9048 9.16908 14.1121 8.62625 13.7855L8.05803 13.4436C7.71468 13.2371 7.28532 13.2371 6.94197 13.4436L6.37375 13.7855C5.83092 14.1121 5.1249 13.9048 4.8448 13.3366L4.5516 12.7418C4.37443 12.3824 4.01323 12.1502 3.61271 12.1384L2.94988 12.1188C2.31666 12.1 1.8348 11.5439 1.90636 10.9145L1.98127 10.2556C2.02653 9.85747 1.84816 9.4669 1.51764 9.24039L0.970637 8.86553C0.448075 8.50741 0.343356 7.77908 0.743858 7.28825L1.16309 6.77446C1.41641 6.46401 1.47752 6.03901 1.32192 5.66976L1.06442 5.05868C0.818426 4.4749 1.1241 3.80557 1.72639 3.60918L2.35684 3.40361C2.73779 3.2794 3.01897 2.9549 3.0877 2.56015L3.20146 1.90686C3.31013 1.28275 3.92914 0.884934 4.54199 1.04535L5.18351 1.21326C5.57114 1.31472 5.98311 1.19375 6.25436 0.898828L6.70325 0.410744Z"
			fill="#005FCB"
		/>
		<path
			d="M5.10059 8.06279L6.74059 9.37479C6.78463 9.41106 6.8362 9.43706 6.89156 9.4509C6.94691 9.46474 7.00465 9.46607 7.06059 9.45479C7.11705 9.4442 7.17058 9.42157 7.21751 9.38844C7.26445 9.35531 7.3037 9.31245 7.33259 9.26279L9.90059 4.86279"
			stroke="white"
			strokeWidth="1.14286"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);