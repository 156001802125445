import { useEffect, useRef } from 'react';

export function usePrevious<T>(value: T) : T | undefined {
	const ref = useRef<T>();

	useEffect(() => {
		ref.current = value;
	});

	return ref.current;
}

export const useUnmount = (cb: () => void) => {
	useEffect(() => cb, []);
};