import styled from 'styled-components';
import { getCssFromTheme } from '../../../utils/css';
import { IStyledProps } from '../../../types';

export const StyledCheckboxWrapper = styled.div<IStyledProps>`
  ${({ theme, labelType }) => getCssFromTheme(theme, labelType || '')};

  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  & input {
    position: absolute;
    z-index: 1;
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0;
  }

  &.disabled {
    cursor: not-allowed;

    & input {
      cursor: not-allowed;
    }

    svg > circle {
      fill: ${({ theme: { color } }) => color.general.lighter};
    }

    svg rect {
      stroke: ${({ theme: { color } }) => color.general.lighter};
    }
  }

  &.error {

    .icon {
      border: 2px solid ${({ theme }) => theme.color.status.error};
      border-radius: 4px;

      svg {
        width: 12px;
        height: 12px;
      }

      g {

        rect {
          fill: transparent;
          stroke: transparent;
        }
      }
    }
  }

  & .typography {
    margin-left: 10px;
  }
`;