import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { FILE_TYPES } from '../constants';
import { uploadFile } from '../api';
import { useWidget } from '../WidgetProvider';
import { IFileUploadResult } from '../types';

interface IProps {
	setFiles: Dispatch<SetStateAction<IFileUploadResult[]>>
}

const InputBlock = styled.input`
  position: absolute;
  inset: 0;
  opacity: 0;
`;

const UploadFile = (props: IProps) => {
	const { setFiles } = props;

	const { license, contactUid } = useWidget();

	const accept = Object.keys({ ...FILE_TYPES.images, ...FILE_TYPES.files }).join(', ');
	const maxLength = 20971520;

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			uploadFile(license, contactUid, e.target.files[0])
				.then((res) => {
					setFiles((prev) => [...prev, res]);
				});
		}
	};

	return (
		<InputBlock
			type="file"
			accept={accept}
			maxLength={maxLength}
			onChange={handleChange}
			onClick={(e) => {
				// @ts-ignore
				e.target.value = null;
			}}
		/>
	);
};

export default UploadFile;