import React from 'react';
import useResizeObserver from 'use-resize-observer';

import FlexRow from '../../components/atomic/FlexRow';
import LiveChatProvider from '../../LiveChatProvider';
import { useWidget } from '../../WidgetProvider';
import CallbackForm from '../CallbackIframe/CallbackForm';
import WidgetButtonForms from '../WidgetButtonContainer/WidgetButtonForms';
import WidgetButton from '../WidgetButtonContainer/WidgetButton';
import CallbackButton from '../CallbackIframe/CallbackButton';
import { usePrevious } from '../../hooks/useReact';
import toggleWidgetVisibility from '../../utils/toggleWidgetVisibility';

const TwoButtonsOnOneSideContainer = () => {
	const { callbackOpened, widgetOpened, widgetSettings } = useWidget();
	const prevCallbackOpened = usePrevious(callbackOpened);
	const prevWidgetOpened = usePrevious(widgetOpened);

	const button_placement = widgetSettings?.settings?.button_placement;

	useResizeObserver({
		ref: document.getElementById('skibble-widget-root'),
		onResize({ height }) {
			if (height === 0) {
				return;
			}

			toggleWidgetVisibility({
				callbackOpened,
				prevCallbackOpened,
				widgetOpened,
				prevWidgetOpened,
			});
		},
	});

	return (
		<LiveChatProvider>
			<CallbackForm className="twoButtonsOnOneSide" />
			<WidgetButtonForms className="twoButtonsOnOneSide" />
			<FlexRow
				gap="20px"
				justifyContent={button_placement === 'right' ? 'end' : 'start'}
			>
				<CallbackButton className="callbackIcon" />
				<WidgetButton className="liveChatIcon" />
			</FlexRow>
		</LiveChatProvider>
	);
};

export default TwoButtonsOnOneSideContainer;