import React from 'react';
import { IPropsSvgIcon } from '../../types';

export const facebookIcon = ({ width, height }: IPropsSvgIcon) => (
	<svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none">
		<circle cx="8" cy="8" r="8" fill="#2196F3" />
		<circle cx="8" cy="8" r="8" fill="url(#paint0_radial_6974_35080)" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3 7.79217C3.0617 5.09038 5.29775 2.9477 8 3.00097C10.7022 2.9477 12.9383 5.09038 13 7.79219C12.9383 10.494 10.7022 12.6367 8 12.5834C7.36656 12.5847 6.7383 12.4693 6.14666 12.243L4.98541 12.9683C4.95231 12.989 4.91404 13 4.875 13C4.75994 13 4.66666 12.9067 4.66666 12.7917V11.3585C3.61609 10.4708 3.00705 9.16748 3 7.79217ZM11.0249 6.35897C11.1159 6.30936 11.2296 6.33422 11.2916 6.4173C11.3539 6.49995 11.3461 6.61582 11.2733 6.68935L8.77327 9.1891C8.6963 9.26609 8.57302 9.2708 8.49036 9.19994L7.1412 8.04381L4.97454 9.22494C4.89366 9.2688 4.7936 9.25443 4.72835 9.18957C4.64673 9.10847 4.64633 8.97657 4.72745 8.89497L7.22745 6.39522C7.30442 6.31823 7.4277 6.31352 7.51036 6.38438L8.85827 7.5401L11.0249 6.35897Z"
			fill="white"
		/>
		<defs>
			<radialGradient
				id="paint0_radial_6974_35080"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(2.68 15.9996) scale(17.6 17.5995)"
			>
				<stop stopColor="#0099FF" />
				<stop offset="0.6" stopColor="#A033FF" />
				<stop offset="0.9" stopColor="#FF5280" />
				<stop offset="1" stopColor="#FF7061" />
			</radialGradient>
		</defs>
	</svg>
);