import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { useInterval } from './useInterval';
import { IResponseChatWidgetLicense } from '../types';

interface IProps {
	timezone_region?: string,
	work_hours?: IResponseChatWidgetLicense['work_hours'],
}

export const useNonWorkingHours = (props: IProps) => {
	const { timezone_region, work_hours } = props;

	const [currentDayTime, setCurrentDayTime] = useState(dayjs());
	useInterval(() => setCurrentDayTime(dayjs()), 30000, []);

	const [isNonWorkingTime, setIsNonWorkingTime] = useState(false);

	const mapDayjsWeekDayToWeekDay = {
		0: 'sunday',
		1: 'monday',
		2: 'tuesday',
		3: 'wednesday',
		4: 'thursday',
		5: 'friday',
		6: 'saturday',
	};

	useEffect(() => {
		if (!timezone_region) {
			return;
		}

		// @ts-ignore
		const currDateTimeInWidgetTimezone = currentDayTime.tz(timezone_region);
		const currDateTimeInWidgetTimezoneFormattedToCompare = currDateTimeInWidgetTimezone.format('YYYY-MM-DD HH:mm');
		const currDateInWidgetTimezoneFormatted = currDateTimeInWidgetTimezone.format('YYYY-MM-DD');
		const currDayjsWeekDay = currDateTimeInWidgetTimezone.$W;
		const prevDayjsWeekDay = currDayjsWeekDay === 0 ? 6 : currDayjsWeekDay - 1;

		// @ts-ignore
		const currDayOfWorkSettings: IResponseChatWidgetLicense['work_hours']['monday'] = work_hours?.[mapDayjsWeekDayToWeekDay[currDayjsWeekDay]];
		// @ts-ignore
		const prevDayOfWorkSettings: IResponseChatWidgetLicense['work_hours']['monday'] = work_hours?.[mapDayjsWeekDayToWeekDay[prevDayjsWeekDay]];
		const isPrevDayOfWorkTimeAfterMidnight = prevDayOfWorkSettings.is_open
			&& prevDayOfWorkSettings?.start >= prevDayOfWorkSettings?.close;
		const isCurrDayOfWorkTimeAfterMidnight = currDayOfWorkSettings?.start
			>= currDayOfWorkSettings?.close;

		if (!currDayOfWorkSettings?.is_open) {
			setIsNonWorkingTime(true);
			return;
		}

		if (
			// if current day work time is after midnight,
			// then we need to check if current time is between current day start time and 23:59;
			// else - between current day start time and current day close time
			// @ts-ignore
			!dayjs(currDateTimeInWidgetTimezoneFormattedToCompare).isBetween(
				`${currDateInWidgetTimezoneFormatted} ${currDayOfWorkSettings.start}`,
				`${currDateInWidgetTimezoneFormatted} ${
					isCurrDayOfWorkTimeAfterMidnight ? '23:59' : currDayOfWorkSettings.close
				}`,
				'minute',
				'[]',
			)
			// if prev day work time is after midnight,
			// then we need to check if current time is between 00:00 and prev day close time;
      // else - do nothing
      && (!isPrevDayOfWorkTimeAfterMidnight
      // @ts-ignore
        || !dayjs(currDateTimeInWidgetTimezoneFormattedToCompare).isBetween(`${currDateInWidgetTimezoneFormatted} 00:00`, `${currDateInWidgetTimezoneFormatted} ${prevDayOfWorkSettings.close}`))
		) {
			setIsNonWorkingTime(true);
			return;
		}

		setIsNonWorkingTime(false);
	}, [currentDayTime, timezone_region, work_hours]);

	return isNonWorkingTime;
};