import React, { Dispatch, SetStateAction } from 'react';
import { useTheme } from 'styled-components';

import Textarea from '../../components/Textarea';
import ChatArea from '../../components/ChatArea';
import FlexRow from '../../components/atomic/FlexRow';
import Icon from '../../components/atomic/Icon';
import Typography from '../../components/atomic/Typography';
import { getT } from '../../utils/getTranslation';
import { useLiveChat } from '../../LiveChatProvider';
import { StyledChatsContainer } from './styled';
import WidgetHeader from '../../components/WidgetHeader';
import { StyledScreenWrapper } from '../WidgetButtonContainer/styled';
import WidgetFooter from '../ChooseChannels/components/WidgetFooter';
import { useWidget } from '../../WidgetProvider';
import { IResponseChatWidgetLicense, ScreenType } from '../../types';

interface IProps {
	auto_reply_settings: IResponseChatWidgetLicense['auto_reply_settings'],
	className: string,
	contactUid: string,
	currentScreen: ScreenType,
	is_hide_branding: boolean,
	isNonWorkingTime: boolean,
	goOneScreenBack?: () => void,
	license: string,
	offlineText: string | false,
	setCurrentScreen: Dispatch<SetStateAction<ScreenType>>,
}

const LiveChatContainer = (props: IProps) => {
	const {
		auto_reply_settings,
		className,
		contactUid,
		currentScreen,
		is_hide_branding,
		isNonWorkingTime,
		goOneScreenBack,
		setCurrentScreen,
		license,
		offlineText,
	} = props;

	const theme = useTheme();

	const {
		history: chatHistory,
		operatorName,
		operatorOnline,
		chatStatus,
	} = useLiveChat();
	const { chatDetails } = useWidget();

	const messageText = chatDetails?.text;

	return (
		<StyledScreenWrapper className={className}>
			<WidgetHeader
				goOneScreenBack={goOneScreenBack}
				offlineText={offlineText}
				setCurrentScreen={setCurrentScreen}
			/>
			<ChatArea
				chatHistory={chatHistory}
				operatorOnline={operatorOnline}
				license={license}
				contactUid={contactUid}
				currentScreen={currentScreen}
			/>

			{operatorName && chatStatus === 'open' && !operatorOnline && (
				<FlexRow
					justifyContent="center"
					className="offlineText"
				>
					<Icon
						margin="0 3px 0 0"
						height={12}
						width={14}
						name="offline"
					/>
					<Typography
						color={theme.color.general.dark}
						variant="input"
						textAlign="center"
					>
						{getT('operatorOffline', { operatorName })}
					</Typography>
				</FlexRow>
			)}

			<StyledChatsContainer className="textarea">
				<Textarea
					auto_reply_settings={auto_reply_settings}
					currentScreen={currentScreen}
					isNonWorkingTime={isNonWorkingTime}
					messageText={messageText ?? ''}
				/>
			</StyledChatsContainer>
			{!is_hide_branding && <WidgetFooter />}
		</StyledScreenWrapper>
	);
};

export default LiveChatContainer;