import styled from 'styled-components';

export const StyledIsTypingIndicator = styled.div`
  position: relative;
  display: flex;
  padding: 15px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.color.general.lighter};

  span {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: ${({ theme }) => theme.color.general.dark};
    animation: blink 1.5s infinite;
    animation-fill-mode: both;

    &:nth-child(2) {
      animation-delay: 0.2s;
      margin-left: 5px;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
      margin-left: 5px;
    }
  }

  @keyframes blink {

    0% {
      opacity: 0.1;
    }

    20% {
      opacity: 1;
    }

    100% {
      opacity: 0.1;
    }
  }
`;