import styled from 'styled-components';
import { getCssFromTheme } from '../../../utils/css';
import { IStyledProps } from '../../../types';

export const StyledLabel = styled.div<IStyledProps>`
  ${({ theme, sizeType }) => getCssFromTheme(theme, 'label', sizeType)};
  
  display: inline-flex;
  margin: ${({ margin }) => margin};
  color: ${({ color, theme }) => color || theme.color.general.black};
  text-transform: ${({ $isUppercase }) => $isUppercase && 'uppercase'};
`;