import styled from 'styled-components';

export const StyledWidgetStartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 37px;
  background-color: ${({ theme }) => theme.color.primary.main};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  .callbackWrapper & {
    padding: 20px;
  }

  .preChatForm & {
    margin-bottom: 20px;
    padding: 20px;
  }

  .closeIcon,
  .arrowLeft {
    width: 32px;
    height: 32px;
    padding: 5px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.color.general.white}40;
  }

  .divToCenterTitle {
    width: 32px;
    height: 32px;
    margin-right: auto;
  }
`;

export const StyledWidgetTitle = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.general.white};

  .titleTexts {
    margin: 0 10px;
  }

  .typography {
    display: block;

    &.subtext {
      opacity: 0.8;
    }
  }
`;

export const StyledChannelsContainer = styled.div`
  margin-top: -22px;
  padding: 0 20px;

  button {
    justify-content: flex-start;
    margin-bottom: 10px;
    border: transparent;
    border-radius: 10px;
    box-shadow: 0 15px 25px rgb(0 0 0 / 8%);
	
    &:hover:not(.disabled) {
      opacity: 1;
    }
  }

  & ~ button.live_chat {
    margin: 10px 20px;
    border-radius: 10px;
  }
`;