import React from 'react';
import useResizeObserver from 'use-resize-observer';

import { useWidget } from '../../WidgetProvider';
import CallbackForm from './CallbackForm';
import CallbackButton from './CallbackButton';
import { usePrevious } from '../../hooks/useReact';
import toggleWidgetVisibility from '../../utils/toggleWidgetVisibility';
import { ChannelStatus } from '../../types';

const CallbackIframe = () => {
	const { isTwoButtonsOnOneSide, callbackOpened, widgetSettings } = useWidget();
	const prevCallbackOpened = usePrevious(callbackOpened);

	const isWidgetButtonActive = widgetSettings?.channels
		&& Object.keys(widgetSettings.channels)
			.some((channelKey) => (
				channelKey !== 'callback'
				// @ts-ignore
				&& widgetSettings.channels[channelKey].status === ChannelStatus.active
			));

	useResizeObserver({
		ref: document.getElementById(`skibble-${!isWidgetButtonActive ? 'widget' : 'callback'}-root`),
		onResize({ height }) {
			if (height === 0 || isTwoButtonsOnOneSide) {
				return;
			}

			toggleWidgetVisibility({
				callbackOpened,
				prevCallbackOpened,
			});
		},
	});

	return (
		<>
			<CallbackButton className={callbackOpened ? 'callbackIcon displayNone' : 'callbackIcon'} />
			<CallbackForm />
		</>
	);
};

export default CallbackIframe;