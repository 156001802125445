import styled from 'styled-components';
import { HEADER_HEIGHT } from '../constants';
import { IStyledProps, WidgetPosition } from '../types';

export const StyledChatsWrapper = styled.div<{ button_placement: WidgetPosition }>`
  position: absolute;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.color.general.white};

  &.extended {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    animation: ${({ button_placement }) => (button_placement === 'left' ? 'extend-left-and-top' : 'extend-right-and-top')}
      0.3s ease-out;
    opacity: 1;

    &.withoutBranding {
      padding-bottom: 10px;
    }
  }

  &.minimised {
    width: 0;
    height: 0;
    overflow: hidden;
    animation: ${({ button_placement }) => (button_placement === 'left' ? 'minimise-left-and-top' : 'minimise-right-and-top')}
      0.3s ease-out;
    opacity: 0;
  }

  &.twoButtonsOnOneSide {
    position: relative;

    &.extended {
      flex-grow: 1;
      margin-bottom: 20px;
      animation: ${({ button_placement }) => (button_placement === 'left' ? 'extend-left' : 'extend-right')}
        0.3s ease-out;
    }

    &.minimised {
      animation: ${({ button_placement }) => (button_placement === 'left' ? 'minimise-left-and-top' : 'minimise-right-and-top')}
        0.3s ease-out;
    }
  }

  .callbackInput {
    margin: 20px;

    &.hasError {
      margin-bottom: 20px;
    }

    input,
    .inputWrapper {
      border-radius: 10px;
    }
  }

  button.callback {
    width: calc(100% - 40px);
    margin: 10px 20px;
    border-radius: 10px;
  }

  @keyframes extend-right-and-top {

    0% {
      opacity: 0;
      transform: translate(100%, 100%);
    }

    80% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  @keyframes minimise-right-and-top {

    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(100%, 100%);
    }
  }

  @keyframes extend-left-and-top {

    0% {
      transform: translate(-100%, 100%);
      opacity: 0;
    }

    100% {
      transform: translate(0, 0);
      opacity: 1;
    }
  }

  @keyframes minimise-left-and-top {

    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(-100%, 100%);
    }
  }

  @keyframes extend-right {

    0% {
      opacity: 0;
      transform: translate(100%);
    }

    80% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
      transform: translate(0);
    }
  }

  @keyframes minimise-right {

    0% {
      transform: translate(0);
    }

    100% {
      transform: translate(100%);
    }
  }

  @keyframes extend-left {

    0% {
      transform: translate(-100%);
      opacity: 0;
    }

    100% {
      transform: translate(0);
      opacity: 1;
    }
  }

  @keyframes minimise-left {

    0% {
      transform: translate(0);
    }

    100% {
      transform: translate(-100%);
    }
  }
`;

export const StyledHeader = styled.div<{offlineText?: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: ${({ offlineText }) => (offlineText ? 'normal' : 'center')};
  padding: 15px 20px;
  background-color: ${({ theme }) => theme.color.primary.main};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  .subtext {
    margin-right: 20px;
    color: ${({ theme }) => theme.color.general.white};
    opacity: 0.8;
  }

  .dropdownItem {
    width: 100%;
  }

  .closeIcon,
  .arrowLeft,
  .dots {
    width: 32px;
    height: 32px;
    padding: 5px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.color.general.white}40;
  }

  .closeIcon {
    margin-left: 5px;
  }

  .dropdown {
    margin-left: 5px;
  }

  .title {
    display: flex;
    color: ${({ theme }) => theme.color.general.white};

    .icon {
      margin-left: 10px;

      path {
        stroke: ${({ theme }) => theme.color.general.white};
      }
    }
  }

  .divToCenterTitle {
    width: 32px;
    height: 32px;
    margin-right: 5px;
  }
`;

export const StyledButtonWrapper = styled.div<{ placement: WidgetPosition }>`
  .notificationIndicator {
    position: absolute;
    top: 0;
    right: 0;
  }

  .liveChatIcon,
  .callbackIcon {
    width: fit-content;
    margin-right: ${({ placement }) => (placement === WidgetPosition.right ? '0' : 'auto')};
    margin-left: ${({ placement }) => (placement === WidgetPosition.left ? '0' : 'auto')};
    animation: pulse 1.5s infinite;

    &:hover {
      animation: none;
    }
  }
`;

export const StyledChatArea = styled.div`
  position: relative;
  height: calc(100% - ${HEADER_HEIGHT}px);
  overflow: hidden;
`;

export const StyledActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;

  .longText {
    display: flex;
    max-width: 100px;
  }

  .longerText {
    display: flex;
    max-width: 250px;
  }
`;

export const StyledAction = styled.div<IStyledProps>`
  .actionDividerContainer {
    display: flex;
    align-items: center;
    max-width: 320px;
    padding: 4px 6px;
    border: 1px solid ${({ borderColor }) => borderColor};
    border-radius: 3px;
    white-space: nowrap;

    a {
      font-size: 10px;
      line-height: 13px;
    }

    & > * {
      margin-right: 4px;
    }
  }
`;

export const StyledLine = styled.hr`
  width: 100%;
  height: 1px;
  border-width: 0;
  background-color: ${({ theme }) => theme.color.general.lighter};
  color: ${({ theme }) => theme.color.general.lighter};
`;

export const StyledChatMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: end;
  padding: 10px 20px;

  &:not(&.operatorMessage) .messageRow {
    max-width: 79%;
  }

  .imagesContainer {
    width: 60%;
  }

  .chatMessage {
    width: 100%;

    .titleAndContent {
      display: flex;
      flex-direction: column;
    }

    .title {
      display: flex;
      margin-bottom: 5px;
      white-space: nowrap;
      gap: 5px;
    }

    .content {
      padding: 8px 15px;
      border-radius: 10px;
      background-color: ${({ theme }) => theme.color.general.lightest};
      color: ${({ theme }) => theme.color.general.dark};
      white-space: pre-wrap;
      align-self: end;
      word-break: break-word;
    }
  }

  &.operatorMessage {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &.isTyping {
      padding: 5px 20px 1px;
    }

    .imagesContainer {
      justify-content: flex-start;
      width: 90%;
    }

    .content {
      background-color: ${({ theme }) => theme.color.general.lighter};
      color: ${({ theme }) => theme.color.general.darker};
    }

    .titleAndContent {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .title {
        align-self: flex-start;
        gap: 5px;
      }

      .content {
        align-self: start;
      }
    }

    .userpic {
      margin-right: 10px;
    }
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: 80%;
    padding: 10px 20px;

    &.operatorMessage {
      width: 70%;
      margin-left: auto;
    }
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xxl}px) {

    &.operatorMessage {
      width: 45%;
    }
  }
`;

export const StyledFileMessage = styled.a`
  position: relative;
  display: flex;
  gap: 10px;
  text-decoration: none;

  .typography {
    max-width: 200px;
    color: ${({ theme }) => theme.color.general.dark};
  }

  .nameContainer {
    display: flex;
    max-width: 300px;
  }

  .fileName {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:hover {
    opacity: 0.7;
  }
`;
export const StyledFilesList = styled.div`
  display: flex;
  margin-top: 5px;
  margin-left: 45px;

  .withText ~ & {
    margin-top: 20px;
  }

  &.imagesContainer {
    position: relative;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 4px;

    figure {
      position: relative;
    }

    img {
      max-height: 200px;
    }

    .downloadElement {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }

  &.filesContainer {
    flex-direction: column;
    gap: 20px;
    max-width: 350px;

    a {
      padding: 5px 10px;
      border: 1px solid ${({ theme }) => theme.color.general.lighter};
      border-radius: 3px;
      }
    }
`;

export const StyledUploadImages = styled.div`
  .actions {
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    padding: 10px 0;
    background-color: #fff;
  }

  &.hidden {
    position: absolute;
    bottom: 35px;
    left: 15px;
    width: calc(100% - 30px);
    opacity: 0;

    div,
    div * {
      height: 22px;
      cursor: text;
    }
  }

  &.active {
    opacity: 1;
    bottom: 5px;

    div {
      height: 90px;
    }
  }

  &.hasErrors {
    bottom: 40px;
    opacity: 1;

    ~ .errorText {
      line-height: 15px;
    }

    div {
      height: 22px;

      .typography {
        height: auto;
      }
    }
  }
`;

export const StyledUploadedItem = styled.div`
  position: absolute;
  bottom: 65px;
  left: 37px;
  display: flex;
  align-items: center;
  width: 80%;
  max-width: 250px;
  gap: 5px;

  .size {
    white-space: nowrap;
  }

  .fileName {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const StyledMessageImage = styled.div<{ url?: string }>`
  position: relative;
  width: 100px;
  height: 100px;
  border: 1px solid ${({ theme }) => theme.color.general.lighter};
  border-radius: 3px;
  background: center / cover no-repeat ${({ url }) => `url(${url})`};
`;