import React from 'react';
import styled from 'styled-components';
import { getT } from '../../../utils/getTranslation';

interface IProps {
	search: string;
}

const Wrap = styled.div`
  padding: 10px;
`;

const Text = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  line-height: 18px;
`;

const EmptyList = ({ search }: IProps) => (
	<Wrap>
		<Text dangerouslySetInnerHTML={{ __html: getT('emptyListSearch', { search }) }} />
	</Wrap>
);

export default EmptyList;