import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

const search = ({ width, height, fill }: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width || 20}
			height={height || 20}
			fill="none"
			viewBox="0 0 20 20"
		>
			<path
				stroke={fill || theme.color.general.white}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={1.143}
				d="M8.766 14.96a6.194 6.194 0 1 0 0-12.389 6.194 6.194 0 0 0 0 12.389ZM17.428 17.428l-4.285-4.285"
			/>
		</svg>
	);
};
export default search;