import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const enableSoundsHeader = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();

	return (
		<svg width={width || 17} height={height || 16} fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.5.5714a4.9027 4.9027 0 0 1 4.9028 4.9029c0 5.4514 1.9886 6.5257 2.5258 6.5257H1.0714c.5486 0 2.5257-1.0857 2.5257-6.5257A4.9029 4.9029 0 0 1 8.5.5714v0ZM6.7856 14.0914a1.7715 1.7715 0 0 0 3.4286 0" stroke={fill || theme.color.general.white} strokeWidth="1.1429" strokeLinecap="round" strokeLinejoin="round" /></svg>
	);
};