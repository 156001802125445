import styled from 'styled-components';

export const StyledChatsContainer = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  padding: 20px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.color.general.white};

  .inputWrapper {
    min-height: 103px;
    padding-bottom: 40px !important;

    &.hasError {
      border-color: ${({ theme }) => theme.color.status.error};
    }
  }

  &.textarea .inputWrapper:focus-within:not(.hasError) {
    outline: none;
    border: 1px solid ${({ theme }) => theme.color.general.light};
  }

  .prompt {
    position: absolute;
    right: 0;
    bottom: -30px;
    letter-spacing: -0.3px;
  }

  .sendButton {
    border-color: ${({ theme }) => theme.color.general.lighter};
    background-color: ${({ theme }) => theme.color.general.white};
  }

  .buttonsRow {
    width: calc(100% - 30px);

    .sendButton {
      margin-left: auto;
    }
  }

  .sendButton:hover:not(.disabled) {
    background-color: ${({ theme }) => theme.color.general.lighter} !important;
  }

  .errorText {
    position: static;
    display: block;
    margin: 5px 15px;
    border: 1px solid ${({ theme }) => theme.color.status.error};
    border-radius: 5px;
    color: ${({ theme }) => theme.color.status.error};
  }

  .textareaActionButton {
    padding: 13px;
    border-radius: 5px;

    .icon {
      margin: 0;
    }
  }

  .attachButton {
    position: relative;
  }

  textarea {
    padding: 8px 15px 0 15px;
  }

  .hasItem {
    padding-bottom: 59px !important;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 14px;
  }
`;