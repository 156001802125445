import React from 'react';
import { mailSend } from './icons/mailSend';
import { attachSign } from './icons/attachSign';
import { smile } from './icons/smile';
import { article } from './icons/article';
import { paginationArrow } from './icons/paginationArrow';
import { skibbleIcon } from './icons/skibbleIcon';
import { forwarded } from './icons/forwarded';
import { statusActive } from './icons/statusActive';
import { starSign } from './icons/starSign';
import cross from './icons/cross';
import { folder } from './icons/folder';
import { like } from './icons/like';
import resizeIn from './icons/resizeIn';
import knowledgeBase from './icons/knowledgeBase';
import { disLike } from './icons/disLike';
import { download } from './icons/download';
import { chatPagination } from './icons/chatPagination';
import { knowledgeBasePagination } from './icons/knowledgeBasePagination';
import { typePDF } from './icons/typePDF';
import { typeTXT } from './icons/typeTXT';
import resizeOut from './icons/resizeOut';
import search from './icons/search';
import { disableSoundsHeader } from './icons/disableSoundsHeader';
import { enableSoundsHeader } from './icons/enableSoundsHeader';
import { fileSign } from './icons/fileSign';
import { chatPaginationActive } from './icons/chatPaginationActive';
import { knowledgeBasePaginationActive } from './icons/knowledgeBasePaginationActive';
import { offline } from './icons/offline';
import { state } from './icons/state';
import { chevronDownThin } from './icons/chevronDownThin';
import { arrowLeft } from './icons/arrowLeft';
import { threeDotsHollow } from './icons/threeDotsHollow';
import { crossBubble } from './icons/crossBubble';
import { typeSkibble } from './icons/typeSkibble';
import { typeQuestion } from './icons/typeQuestion';
import { typeBubble } from './icons/typeBubble';
import { typeBubbles } from './icons/typeBubbles';
import { typeBriefcase } from './icons/typeBriefcase';
import { enableSounds } from './icons/enableSounds';
import { disableSounds } from './icons/disableSounds';
import { typeSkibbleWithNotifications } from './icons/typeSkibbleWithNotifications';
import { typeBriefcaseWithNotifications } from './icons/typeBriefcaseWithNotifications';
import { typeQuestionWithNotifications } from './icons/typeQuestionWithNotifications';
import { typeBubbleWithNotifications } from './icons/typeBubbleWithNotifications';
import { typeBubblesWithNotifications } from './icons/typeBubblesWithNotifications';
import { skibbleTextLogo } from './icons/skibbleTextLogo';
import { chats } from './icons/chats';
import { facebookIcon } from './icons/facebookIcon';
import { instagramIcon } from './icons/instagramIcon';
import { viberIcon } from './icons/viberIcon';
import { telegramIcon } from './icons/telegramIcon';
import { checkboxChecked } from './icons/checkboxChecked';
import { checkboxUnchecked } from './icons/checkboxUnchecked';
import { contactCard } from './icons/contactCard';
import { doggySign } from './icons/doggySign';
import { multiUsers } from './icons/multiUsers';
import { phone } from './icons/phone';
import { bot } from './icons/bot';
import { typeCallback } from './icons/typeCallback';
import { inputError } from './icons/inputError';
import { IPropsSvgIcon, NameTypeIcon } from '../types';

const Icons: Record<NameTypeIcon, React.FC<IPropsSvgIcon>> = {
	cross,
	download,
	mailSend,
	starSign,
	statusActive,
	disableSoundsHeader,
	chatPaginationActive,
	knowledgeBasePaginationActive,
	enableSoundsHeader,
	paginationArrow,
	skibbleIcon,
	chatPagination,
	knowledgeBasePagination,
	forwarded,
	inputError,
	attachSign,
	smile,
	typeTXT,
	typePDF,
	fileSign,
	offline,
	state,
	folder,
	like,
	disLike,
	article,
	chevronDownThin,
	arrowLeft,
	knowledgeBase,
	threeDotsHollow,
	crossBubble,
	typeSkibble,
	typeQuestion,
	typeBubble,
	typeBubbles,
	search,
	resizeOut,
	enableSounds,
	resizeIn,
	disableSounds,
	typeBriefcase,
	typeSkibbleWithNotifications,
	typeBriefcaseWithNotifications,
	typeQuestionWithNotifications,
	typeBubbleWithNotifications,
	typeBubblesWithNotifications,
	skibbleTextLogo,
	chats,
	facebookIcon,
	instagramIcon,
	viberIcon,
	telegramIcon,
	checkboxChecked,
	checkboxUnchecked,
	contactCard,
	doggySign,
	multiUsers,
	phone,
	bot,
	typeCallback,
};

export default Icons;