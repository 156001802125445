import React, { Dispatch, SetStateAction } from 'react';

import { StyledScreenWrapper } from '../WidgetButtonContainer/styled';
import WidgetFooter from '../ChooseChannels/components/WidgetFooter';
import WidgetKnowledgeBaseHeader from '../../components/WidgetKnowledgeBaseHeader';
import Article from './components/Article';
import PaginationTabs from '../../components/PaginationTabs';
import { ScreenType } from '../../types';
import { useWidget } from '../../WidgetProvider';

interface IProps {
	screens: ScreenType[],
	currentScreen: ScreenType,
	setCurrentScreen: Dispatch<SetStateAction<ScreenType>>
	className: string,
	goOneScreenBack?: () => void,
	is_hide_branding: boolean,
}

const KnowledgeBaseArticle = (props: IProps) => {
	const {
		screens,
		currentScreen,
		setCurrentScreen,
		className,
		goOneScreenBack,
		is_hide_branding,
	} = props;

	const { setKnowledgeBaseItemId } = useWidget();

	const handleScreenBack = () => {
		setKnowledgeBaseItemId(null);

		if (goOneScreenBack) {
			goOneScreenBack();
		}
	};

	return (
		<StyledScreenWrapper className={className}>
			<WidgetKnowledgeBaseHeader
				goOneScreenBack={handleScreenBack}
			/>
			<Article />
			<PaginationTabs
				screens={screens}
				currentScreen={currentScreen}
				setCurrentScreen={setCurrentScreen}
			/>
			{!is_hide_branding && <WidgetFooter />}
		</StyledScreenWrapper>
	);
};

export default KnowledgeBaseArticle;