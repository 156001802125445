import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

const resizeOut = ({ width, height, fill }: IPropsSvgIcon) => {
	const theme = useTheme();

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width || 20}
			height={height || 20}
			fill="none"
			viewBox="0 0 20 20"
		>
			<g
				stroke={fill || theme.color.general.white}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={1.143}
				clipPath="url(#a)"
			>
				<path d="M8.286 8.286 2.57 2.57M7.143 2.571H2.57v4.572M11.714 11.714l5.714 5.714M12.857 17.429h4.572v-4.572" />
			</g>
			<defs>
				<clipPath id="a">
					<path fill={fill || theme.color.general.white} d="M2 2h16v16H2z" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default resizeOut;