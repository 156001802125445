import React from 'react';
import clsx from 'clsx';

import { useTheme } from 'styled-components';
import { StyledButton } from './styled';
import Icon from '../Icon';
import { IProps as IPropsIcon } from '../Icon/Icon';

interface IProps {
	as?: string,
	backgroundColor?: string,
	borderColor?: string,
	borderRadius?: string,
	children?: React.ReactNode,
	className: string,
	color?: string,
	disabled?: boolean,
	fullWidth?: boolean,
	iconLeftProps?: IPropsIcon,
	iconRightProps?: IPropsIcon,
	margin?: string,
	maxWidth?: string,
	onClick?: (e: React.FormEvent) => void,
	padding?: string,
	size?: 'small' | 'medium',
	text?: string,
	type?: 'button' | 'reset' | 'submit',
	uppercase?: boolean,
	variant?: 'primary' | 'secondary' | 'general',
	withoutBorder?: boolean,
}

const typographyVariant = {
	small: 'button3',
	medium: 'button1',
};

const Button = (props: IProps) => {
	const {
		as,
		backgroundColor,
		borderColor,
		borderRadius,
		children,
		className,
		color,
		disabled,
		fullWidth,
		iconRightProps,
		iconLeftProps,
		margin,
		maxWidth,
		onClick,
		padding,
		size,
		text,
		type,
		uppercase,
		variant,
		withoutBorder,
	} = props;

	const theme = useTheme();

	const onBtnClick = (e: React.FormEvent) => {
		if (onClick) {
			e.preventDefault();
			onClick(e);
		}
	};

	return (
		<StyledButton
			as={as}
			backgroundColor={backgroundColor}
			borderRadius={borderRadius}
			borderColor={borderColor}
			color={color}
			disabled={disabled}
			margin={margin}
			maxWidth={maxWidth}
			onClick={onBtnClick}
			padding={padding}
			type={type}
			typographyVariant={size && typographyVariant[size]}
			variant={variant}
			className={clsx(
				className,
				variant,
				disabled && 'disabled',
				fullWidth && 'fullWidth',
				uppercase && 'uppercase',
				withoutBorder && 'withoutBorder',
			)}
		>
			{(iconLeftProps) && (
				<Icon
					fill={color || theme.color.general.white}
					margin="0 6px 0 0"
					/* eslint-disable-next-line react/jsx-props-no-spreading */
					{...iconLeftProps}
				/>
			)}
			{children || text}
			{iconRightProps && (
				<Icon
					fill={color || theme.color.general.white}
					margin="0 0 0 6px"
					/* eslint-disable-next-line react/jsx-props-no-spreading */
					{...iconRightProps}
				/>
			)}
		</StyledButton>
	);
};

export default Button;

Button.defaultProps = {
	size: 'medium',
	type: 'button',
	variant: 'primary',
};