import React, {
	Dispatch, SetStateAction, useEffect, useState,
} from 'react';

import { StyledScreenWrapper } from '../WidgetButtonContainer/styled';
import WidgetFooter from '../ChooseChannels/components/WidgetFooter';
import WidgetKnowledgeBaseHeader from '../../components/WidgetKnowledgeBaseHeader';
import List from './components/List';
import { useWidget } from '../../WidgetProvider';
import { IKnowledgeBaseResponse, ScreenType } from '../../types';
import { getKnowledgeBaseArticleSearchList } from '../../api';
import PaginationTabs from '../../components/PaginationTabs';

interface IProps {
	screens: ScreenType[],
	currentScreen: ScreenType,
	className: string,
	is_hide_branding: boolean,
	setCurrentScreen: Dispatch<SetStateAction<ScreenType>>;
}

let cacheData: IKnowledgeBaseResponse | null = null;
let cacheSearch: string = '';

const KnowledgeBaseList = (props: IProps) => {
	const {
		screens,
		currentScreen,
		className,
		is_hide_branding,
		setCurrentScreen,
	} = props;

	const { knowledgeBaseResponse, license } = useWidget();

	const [data, setData] = useState< IKnowledgeBaseResponse | null>(cacheData);

	const [search, setSearch] = useState(cacheSearch);

	const handleChangeSearch = (val: string) => {
		setSearch(val);
		cacheSearch = val;
	};

	const getData = (searchLocal: string) => {
		const timeStamp = new Date();

		// need for cache timeStamp
		// @ts-ignore
		// eslint-disable-next-line no-underscore-dangle
		window.__timeStampSearchRequest__ = timeStamp;

		if (searchLocal) {
			getKnowledgeBaseArticleSearchList(license, searchLocal)
				.then((res) => {
					// only the last request to be shown
					// @ts-ignore
					// eslint-disable-next-line no-underscore-dangle
					if (window.__timeStampSearchRequest__ === timeStamp) {
						cacheData = res;
						setData(res);
					}
				})
				.catch(() => null);
		} else {
			cacheData = null;
			setData(null);
		}
	};

	useEffect(() => {
		getData(search);
	}, [search]);

	return (
		<StyledScreenWrapper className={className}>
			<WidgetKnowledgeBaseHeader
				search={search}
				setSearch={handleChangeSearch}
				goOneScreenBack={search ? () => handleChangeSearch('') : undefined}
			/>

			<List
				search={search}
				data={data || knowledgeBaseResponse}
				setCurrentScreen={setCurrentScreen}
			/>
			<PaginationTabs
				screens={screens}
				currentScreen={currentScreen}
				setCurrentScreen={setCurrentScreen}
			/>
			{!is_hide_branding && <WidgetFooter />}
		</StyledScreenWrapper>
	);
};

export default KnowledgeBaseList;