import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { getT } from '../../../utils/getTranslation';
import Icon from '../../../components/atomic/Icon';
import { sendKnowledgeBaseArticleInteract } from '../../../api';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 15px 13px 15px;
  background-color: ${({ theme }) => theme.color.general.lightest};
`;

const Title = styled.div`
  padding: 6px 0;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  line-height: 18px;
`;

const IconWrap = styled.div`
  display: flex;
  gap: 20px;
`;

const IconBlock = styled.div<{ hoverColor: string }>`
  cursor: pointer;
  
  &:hover path {
    stroke: ${({ hoverColor }) => hoverColor};
}
`;

type TypeFeedback = 'like' | 'dislike';

const localStorageKey = 'knowledgeBaseArticleInteracts';

const Feedback = ({ id }: { id: number }) => {
	const [hideFeedback, setHideFeedback] = useState(false);
	const [completed, setCompleted] = useState(false);

	useEffect(() => {
		const fromStorage = localStorage.getItem(localStorageKey);

		if (fromStorage) {
			try {
				const array: { id: string, timestamp: number }[] = JSON.parse(fromStorage);

				const inArray = array.find((i) => i.id === id.toString());

				setHideFeedback(!!inArray);
			} catch (e) {
				// empty error
			}
		}
	}, [id]);

	const theme = useTheme();

	const saveInteractToLocalStorage = () => {
		const fromStorage = localStorage.getItem(localStorageKey);

		const newItem = { id: id.toString(), timestamp: Date.now() };

		if (fromStorage) {
			try {
				localStorage.setItem(localStorageKey, JSON.stringify([
					...JSON.parse(fromStorage),
					newItem,
				]));
			} catch (e) {
				localStorage.setItem(localStorageKey, JSON.stringify([
					newItem,
				]));
			}
		} else {
			localStorage.setItem(localStorageKey, JSON.stringify([
				newItem,
			]));
		}
	};

	const handleClick = (type: TypeFeedback) => {
		sendKnowledgeBaseArticleInteract(id, type)
			.then(() => {
				setCompleted(true);
				saveInteractToLocalStorage();
			})
			.catch(() => null);
	};

	if (hideFeedback) {
		return null;
	}

	return (
		<Wrap>
			<Title>
				{getT(completed ? 'feedbackComplete' : 'feedbackText')}
			</Title>
			{completed ? null : (
				<IconWrap>
					<IconBlock
						hoverColor={theme.color.status.success}
						onClick={() => handleClick('like')}
					>
						<Icon name="like" />
					</IconBlock>
					<IconBlock
						hoverColor={theme.color.status.error}
						onClick={() => handleClick('dislike')}
					>
						<Icon name="disLike" />
					</IconBlock>
				</IconWrap>
			)}
		</Wrap>
	);
};

export default Feedback;