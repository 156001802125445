import {
	Dispatch, SetStateAction,
	useCallback, useEffect, useRef, useState,
} from 'react';
import { pick } from 'dot-object';

import { useWidget } from '../WidgetProvider';
import { useLiveChat } from '../LiveChatProvider';
import { useSendEventWebsocket } from './useWidgetWebsocket';
import { usePrevious, useUnmount } from './useReact';
import { storageAvailable } from '../utils';
import { IResponseChatWidgetLicense, ScreenType } from '../types';

interface IProps {
	auto_reply_settings: IResponseChatWidgetLicense['auto_reply_settings'],
	setCurrentScreen: Dispatch<SetStateAction<ScreenType>>,
	isNonWorkingTime: boolean,
	currentScreen: ScreenType,
}

export const SYSTEM_MESSAGES_TYPES = [
	'live_chat_delayed.first_message',
	'live_chat_delayed.second_message',
	'live_chat_delayed.idle_operator_open_chat',
	'live_chat_delayed.idle_operator_new_chat',
	'live_chat_delayed.idle_guest',
	'all_channels_instant.first_message',
	'all_channels_instant.second_message',
	'all_channels_instant.non_working_hours',
];

export const useSystemMessagesWithTimeouts = (props: IProps) => {
	const {
		auto_reply_settings,
		setCurrentScreen,
		isNonWorkingTime,
		currentScreen,
	} = props;

	const { live_chat_delayed } = auto_reply_settings;
	const {
		first_message,
		second_message,
		idle_operator_open_chat,
		idle_operator_new_chat,
		idle_guest,
	} = live_chat_delayed;

	const { widgetOpened, setWidgetOpened, chatStartedOrigin } = useWidget();
	const {
		setHistoryEvent,
		eventJustSent,
		eventJustReceived,
		chatStatus,
		history,
		currentHistoryCount,
		setHistory,
	} = useLiveChat();

	const [isChannelButtonClicked, setIsChannelButtonClicked] = useState('');

	const [liveChatOpenedAfterFirstAutoReply, setLiveChatOpenedAfterFirstAutoReply] = useState(false);

	useEffect(() => {
		if (liveChatOpenedAfterFirstAutoReply && !widgetOpened && storageAvailable()) {
			setLiveChatOpenedAfterFirstAutoReply(false);
			localStorage.setItem(`hideAutoReplyTill-${chatStartedOrigin}`, (Date.now() + 86400000).toString());
		}
	}, [liveChatOpenedAfterFirstAutoReply, widgetOpened]);

	const constructMessageData = (type: string) => ({
		type: 'system_message',
		data: { text: pick(type, auto_reply_settings).text as string },
	});

	const onSend = (messageType: string) => {
		if (SYSTEM_MESSAGES_TYPES.includes(messageType)) {
			const data = constructMessageData(messageType);
			// eslint-disable-next-line no-use-before-define
			sendEventToServer(data);
		}
	};

	const openLiveChat = () => {
		setWidgetOpened(true, 'widget');
		setCurrentScreen('liveChat');
	};

	const timeoutIds = useRef<Record<string, NodeJS.Timeout>>({});

	const onMessageSentSuccess = (eventType: string, callback: () => void) => {
		if (SYSTEM_MESSAGES_TYPES.includes(eventType)) {
			const eventsName = [
				'live_chat_delayed.idle_operator_open_chat',
				'live_chat_delayed.idle_guest',
			];

			const operatorName = eventsName.includes(eventType)
				? [...history].reverse().find((item) => item.operator_name)?.operator_name
				: null;

			const operatorPic = eventsName.includes(eventType)
				? [...history].reverse().find((item) => item.operator_pic)?.operator_pic
				: null;

			// @ts-ignore
			setHistoryEvent({
				...constructMessageData(eventType),
				operator_name: operatorName || '',
				operator_pic: operatorPic || '',
			});
			clearTimeout(timeoutIds.current[eventType]);
			callback();
			openLiveChat();
		}
	};

	const { sendEventToServer, onSendEvent } = useSendEventWebsocket(onSend, onMessageSentSuccess);

	const onSetTimeout = useCallback(
		(idKey: string, delay: number) => {
			timeoutIds.current[idKey] = setTimeout(() => {
				onSendEvent(idKey)();
			}, delay * 1000);
		},
		[onSendEvent],
	);

	const onClearTimeout = (idKey: string) => {
		if (timeoutIds.current?.[idKey]) {
			clearTimeout(timeoutIds.current[idKey]);
			// @ts-ignore
			timeoutIds.current[idKey] = null;
		}
	};

	const prevChatStatus = usePrevious(chatStatus);

	useEffect(() => {
		let hideAutoReply = false;
		if (storageAvailable()) {
			const hideAutoReplyTillMs = localStorage.getItem(`hideAutoReplyTill-${chatStartedOrigin}`);
			if (hideAutoReplyTillMs) {
				const currentDateInMs = Date.now();
				hideAutoReply = currentDateInMs < parseInt(hideAutoReplyTillMs, 10);
			}
		}
		const getIsOpenLiveChat = () => (
			!hideAutoReply
			&& !isNonWorkingTime
			&& (!prevChatStatus || prevChatStatus === 'closed')
			&& (!chatStatus || chatStatus === 'closed')
			&& !timeoutIds.current['live_chat_delayed.first_message']
			&& live_chat_delayed?.first_message?.status === 'active'
			&& history.length === 0
		);

		if (getIsOpenLiveChat()) {
			// if guest doesn't send any messages we show first system message
			timeoutIds.current['live_chat_delayed.first_message'] = setTimeout(() => {
				// @ts-ignore
				setHistory((prev) => [
					...prev.filter((item) => item.type !== 'show_rating_options'),
					constructMessageData('live_chat_delayed.first_message'),
				]);
				openLiveChat();
				setLiveChatOpenedAfterFirstAutoReply(true);
				// message is placed in queue and shown to user, but not sent yet;
				// will be sent when chatStarted (it's library feature)
				onSend('live_chat_delayed.first_message');
				onClearTimeout('live_chat_delayed.first_message');

				// if guest still doesn't send any messages - we show second system message
				if (live_chat_delayed?.second_message?.status === 'active') {
					timeoutIds.current['live_chat_delayed.second_message'] = setTimeout(() => {
						// @ts-ignore
						setHistoryEvent(constructMessageData('live_chat_delayed.second_message'));
						openLiveChat();
						// message is placed in queue  and shown to user,but not sent yet;
						// will be sent when chatStarted (it's websockets library feature)
						onSend('live_chat_delayed.second_message');
						onClearTimeout('live_chat_delayed.second_message');
					}, second_message.delay * 1000);
				}
			}, first_message.delay * 1000);
		}
	}, [isNonWorkingTime, currentHistoryCount, chatStatus]);

	// idle_guest and idle_operator
	useEffect(() => {
		if (eventJustSent === 'contact_message' || eventJustSent === 'chat_closed') {
			onClearTimeout('live_chat_delayed.idle_guest');
			onClearTimeout('live_chat_delayed.first_message');
			onClearTimeout('live_chat_delayed.second_message');
		}

		if (eventJustSent === 'chat_closed') {
			onClearTimeout('live_chat_delayed.idle_operator_open_chat');
			onClearTimeout('live_chat_delayed.idle_operator_new_chat');
		}

		if (isNonWorkingTime) {
			return;
		}

		if (
			chatStatus === 'open'
      && eventJustSent === 'contact_message'
      && idle_operator_open_chat.status === 'active'
      && !timeoutIds.current?.['live_chat_delayed.idle_operator_open_chat']
		) {
			onSetTimeout('live_chat_delayed.idle_operator_open_chat', idle_operator_open_chat.delay);
		}

		if (
			chatStatus === 'new'
      && eventJustSent === 'contact_message'
      && idle_operator_new_chat.status === 'active'
      && !timeoutIds.current?.['live_chat_delayed.idle_operator_new_chat']
		) {
			onSetTimeout('live_chat_delayed.idle_operator_new_chat', idle_operator_new_chat.delay);
		}
	}, [eventJustSent, chatStatus]);

	useEffect(() => {
		if (eventJustReceived === 'operator_joined') {
			onClearTimeout('live_chat_delayed.idle_operator_new_chat');
		}

		if (eventJustReceived === 'operator_message' || eventJustReceived === 'chat_closed') {
			onClearTimeout('live_chat_delayed.idle_operator_open_chat');
			onClearTimeout('live_chat_delayed.idle_operator_new_chat');
		}

		if (eventJustReceived === 'chat_closed') {
			onClearTimeout('live_chat_delayed.idle_guest');
		}

		if (isNonWorkingTime) {
			return;
		}

		if (chatStatus === 'open' && eventJustReceived === 'operator_message' && idle_guest.status === 'active') {
			clearTimeout(timeoutIds.current?.['live_chat_delayed.idle_guest']);
			onSetTimeout('live_chat_delayed.idle_guest', idle_guest.delay);
		}
	}, [eventJustReceived, chatStatus]);

	useEffect(() => {
		if (chatStatus === 'closed' || isNonWorkingTime) {
			Object.keys(timeoutIds.current).forEach((timeoutId) => {
				onClearTimeout(timeoutId);
			});
		}
	}, [chatStatus, isNonWorkingTime]);

	useEffect(() => {
		if (
			isChannelButtonClicked === 'messenger'
      || (currentScreen === 'preChatForm' && isChannelButtonClicked === 'live_chat')
		) {
			onClearTimeout('live_chat_delayed.first_message');
			onClearTimeout('live_chat_delayed.second_message');
			setIsChannelButtonClicked('');
		}
	}, [isChannelButtonClicked]);

	useUnmount(() => {
		Object.values(timeoutIds.current).forEach((timeoutId) => {
			clearTimeout(timeoutId);
		});
	});

	return { setIsChannelButtonClicked };
};