import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IKnowledgeBaseArticleDetailsResponse } from '../../../types';
import { useWidget } from '../../../WidgetProvider';
import { getKnowledgeBaseArticle, sendKnowledgeBaseArticleInteract } from '../../../api';
import Feedback from './Feedback';

const Wrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 10px;
  padding: 5px 10px 10px 10px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: ${({ theme }) => theme.color.general.darker};
  }

  &::-webkit-scrollbar-track {
    margin: 10px 0;
    border-radius: 4px;
    background: ${({ theme }) => theme.color.general.darkest}26;
  }
`;
const Title = styled.div`
  margin: 5px 0 20px 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
`;
const Content = styled.div`
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 23px;
  
  img {
    max-width: 100%;
    border-radius: 3px;
  }
`;

let timeStamp: number;

const Article = () => {
	const [data, setData] = useState<IKnowledgeBaseArticleDetailsResponse | null>(null);

	const { knowledgeBaseItemId } = useWidget();

	useEffect(() => {
		if (knowledgeBaseItemId) {
			getKnowledgeBaseArticle(knowledgeBaseItemId)
				.then(setData)
				.catch(() => null);
		} else {
			setData(null);
		}
	}, [knowledgeBaseItemId]);

	useEffect(() => {
		timeStamp = Date.now();

		if (knowledgeBaseItemId) {
			sendKnowledgeBaseArticleInteract(knowledgeBaseItemId, 'view')
				.then(() => null)
				.catch(() => null);
		}

		return () => {
			if (knowledgeBaseItemId) {
				const amount = Math.round((Date.now() - timeStamp) / 1000);

				sendKnowledgeBaseArticleInteract(knowledgeBaseItemId, 'time_spent', amount)
					.then(() => null)
					.catch(() => null);
			}
		};
	}, [knowledgeBaseItemId]);

	if (!data) {
		return <Wrap />;
	}

	return (
		<Wrap>
			<Title>{data.title}</Title>
			<Content dangerouslySetInnerHTML={{ __html: data.context }} />
			{data.article_interactions ? (
				<Feedback id={data.id} />
			) : null}
		</Wrap>
	);
};

export default Article;