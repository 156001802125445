import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const article = ({ width, height, fill }: IPropsSvgIcon) => {
	const theme = useTheme();

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width || 20} height={height || 20} fill="none">
			<path fill={fill || `${theme.color.primary.main}40`} d="m4.385 5.56 2.96-2.96h6.906a.986.986 0 0 1 .987.986v12.827a.986.986 0 0 1-.987.987h-8.88a.987.987 0 0 1-.986-.987V5.56Z" />
			<path fill={fill || `${theme.color.primary.main}40`} d="M4.385 6.053H6.85a.987.987 0 0 0 .987-.987V2.6" />
			<path fill={theme.color.primary.main} d="M6.851 7.95a.57.57 0 0 0 0 1.14V7.95Zm5.92 1.14a.57.57 0 0 0 0-1.14v1.14Zm-5.92 2.806a.57.57 0 0 0 0 1.14v-1.14Zm5.92 1.14a.57.57 0 0 0 0-1.14v1.14ZM4.385 5.56l-.403-.403a.57.57 0 0 0-.167.403h.57Zm2.96-2.96v-.57a.57.57 0 0 0-.403.167l.403.403Zm7.604.289-.403.403.403-.403Zm0 14.221-.403-.403.403.403Zm-9.578.29v-.57.57Zm1.48-7.477a.57.57 0 0 0 0 1.14v-1.14Zm5.92 1.14a.57.57 0 0 0 0-1.14v1.14Zm-5.92 2.807a.57.57 0 0 0 0 1.14v-1.14Zm2.96 1.14a.57.57 0 1 0 0-1.14v1.14Zm-2.96-5.92h5.92V7.95h-5.92v1.14Zm0 3.946h5.92v-1.14h-5.92v1.14ZM4.788 5.963l2.96-2.96-.806-.806-2.96 2.96.806.806ZM7.345 2.6v.57H14.252V2.03H7.345v.57Zm6.906.57c.111 0 .217.044.295.122l.806-.806a1.556 1.556 0 0 0-1.1-.456v1.14Zm.295.122a.416.416 0 0 1 .122.294h1.14c0-.413-.164-.808-.456-1.1l-.806.806Zm.122.294V16.413h1.14V3.586h-1.14Zm0 12.827c0 .11-.044.216-.122.294l.806.807c.292-.292.456-.688.456-1.101h-1.14Zm-.122.295a.416.416 0 0 1-.295.122v1.14c.413 0 .81-.164 1.101-.457l-.806-.805Zm-.295.122H5.371v1.14H14.251v-1.14Zm-8.88 0a.417.417 0 0 1-.416-.417h-1.14c0 .86.697 1.557 1.556 1.557v-1.14Zm-.416-.417V5.56h-1.14V16.413h1.14Zm-.57-10.36v.57H6.852v-1.14H4.385v.57Zm2.466.57c.86 0 1.557-.697 1.557-1.557h-1.14c0 .23-.186.417-.417.417v1.14Zm1.557-1.557V2.6h-1.14V5.067h1.14Zm-1.557 5.997h5.92v-1.14h-5.92v1.14Zm0 3.947h2.96v-1.14h-2.96v1.14Z" />
		</svg>
	);
};