import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import clsx from 'clsx';

import { StyledCheckboxWrapper } from './styled';
import Typography from '../Typography';
import Icon from '../Icon';

interface IProps {
	checked: boolean,
	className: string,
	disabled?: boolean,
	fontWeight?: number | string,
	handleChange: (state: boolean, e: { target: { name: string, value: string }}) => void,
	label?: string,
	labelType?: 'h1' |'h2' | 'h3' | 'h4' | 'caption1' | 'caption2' | 'caption3' | 'body1' | 'body2' | 'body3' | 'button1' | 'input',
	name: string,
	readOnly?: boolean,
	type?: 'checkbox' | 'radio',
	value?: string,
	children: React.ReactNode,
	fill?: string,
	withLabelChildren: boolean,
}

const Checkbox = (props: IProps) => {
	const {
		checked,
		className,
		disabled,
		fontWeight,
		handleChange,
		label,
		labelType,
		name,
		readOnly,
		type,
		value,
		children,
		fill,
		withLabelChildren,
	} = props;

	const theme = useTheme();
	const [stateChecked, setStateChecked] = useState(checked || false);

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (handleChange) {
			handleChange(e.target.checked, e);
		}
		setStateChecked(e.target.checked);
	};

	const onLabelClick = () => {
		setStateChecked((prev) => (type === 'radio' ? true : !prev));
		if (handleChange) {
			handleChange(type === 'radio'
				? true
				: !stateChecked, { target: { name, value: value || '' } });
		}
	};

	useEffect(() => {
		setStateChecked(checked);
	}, [checked]);

	return (
		<StyledCheckboxWrapper className={clsx(className, disabled && 'disabled', type)}>
			<input
				type={type}
				checked={stateChecked}
				disabled={disabled}
				name={name}
				onChange={disabled || readOnly ? undefined : onChange}
				readOnly={readOnly}
				value={value}
			/>
			{stateChecked && type === 'checkbox' ? (
				<Icon
					name="checkboxChecked"
					fill={fill || theme.color.secondary.main}
				/>
			) : null}
			{!stateChecked && type === 'checkbox' ? (
				<Icon name="checkboxUnchecked" />
			) : null}
			{(withLabelChildren || label) && (
				<Typography
					variant={labelType}
					onClick={disabled || readOnly ? undefined : onLabelClick}
					fontWeight={fontWeight}
				>
					{label || children}
				</Typography>
			)}
			{!withLabelChildren && children}
		</StyledCheckboxWrapper>
	);
};

export default Checkbox;

Checkbox.defaultProps = {
	labelType: 'input',
	type: 'checkbox',
};