import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const chatPaginationActive = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();

	return (
		<svg width={width || 26} height={height || 26} fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M25.0502 9.8401h-5.7834v.7501c0 1.2406-1.0093 2.25-2.25 2.25H8.2335v7.3899a.75.75 0 0 0 .75.75h10.0921l3.7917 4.5501a.7501.7501 0 0 0 .5763.2699c.4962-.0001.7499-.4262.7499-.75v-4.07h.8567a.75.75 0 0 0 .75-.75v-9.64a.75.75 0 0 0-.75-.75Zm-8.7834 9.4267h-4.07a.75.75 0 0 1 0-1.5h4.07a.75.75 0 0 1 0 1.5Zm5.57-3.2133h-9.64a.75.75 0 0 1 0-1.5h9.64a.75.75 0 0 1 0 1.5Z" fill={fill || theme.color.primary.main} />
			<path d="M17.7668 10.5902v-9.64a.75.75 0 0 0-.75-.75H.9502a.75.75 0 0 0-.75.75v9.64a.75.75 0 0 0 .75.75h.8567v4.07c0 .2946.2255.75.7498.75a.75.75 0 0 0 .5763-.2699l3.7918-4.5501h10.092a.75.75 0 0 0 .75-.75ZM8.2335 8.1268h-4.07a.75.75 0 0 1 0-1.5h4.07a.75.75 0 0 1 0 1.5Zm5.57-3.2132h-9.64a.75.75 0 0 1 0-1.5h9.64a.75.75 0 0 1 0 1.5Z" fill={fill || theme.color.primary.main} />
		</svg>
	);
};