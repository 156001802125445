import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const statusActive = ({
	width, height, fill, stroke,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 17} height={height || 16} viewBox="0 0 17 16" fill="none">
			<path
				d="M8.49986 15.4294C12.6025 15.4294 15.9284 12.1035 15.9284 8.00084C15.9284 3.89815 12.6025 0.572266 8.49986 0.572266C4.39717 0.572266 1.07129 3.89815 1.07129 8.00084C1.07129 12.1035 4.39717 15.4294 8.49986 15.4294Z"
				fill={fill || theme.color.status.success}
				fillOpacity="0.25"
				stroke={stroke || theme.color.status.success}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.07129 9.14369L7.41415 11.018C7.47707 11.0698 7.55074 11.1069 7.62982 11.1267C7.7089 11.1465 7.79139 11.1484 7.87129 11.1323C7.95196 11.1171 8.02842 11.0848 8.09547 11.0375C8.16252 10.9901 8.21859 10.9289 8.25986 10.858L11.9284 4.57227"
				stroke={stroke || theme.color.status.success}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};