import styled from 'styled-components';

export const StyledScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.color.general.white};

  &.liveChat {
    border-radius: 14px;

    .widgetFooter {
      padding: 10px 20px;
    }
  }
  
  .textarea {
    padding: 20px 20px 10px;
  }

  .scrollButton {
    position: absolute;
    right: 20px;
    bottom: 0;
    z-index: 5;
    width: 40px;
    height: 40px;

    &.displayed {
      opacity: 1;
      animation: to-show-button 0.5s ease-in;
    }

    &.hide {
      opacity: 0;
      cursor: auto;
      pointer-events: none;
    }

    .icon {
      transform: rotate(-90deg);
    }
  }

  &.preChatForm {
    justify-content: space-between;

    .checkbox {
      align-items: flex-start;
      margin: 0 20px;

      .icon {
        margin-top: 2px;
      }

      .typography {

        a {
          color: ${({ color }) => color || '#FF5F00'};
          text-decoration: none;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }

    .inputContainer,
    .inputWrapper {
      margin-right: 20px;
      margin-left: 20px;
    }

    .inputWrapper.hasError {
      margin-bottom: 20px;
    }

    .selectWrapper,
    .react-select__control {
      width: 100%;
      border-radius: 10px;
    }

    .textarea {
      margin-top: 0;
      padding: 0;
      padding-bottom: 10px;
    }
  }

  @keyframes to-show-button {

    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;