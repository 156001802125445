import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const chevronDownThin = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();

	return (
		<svg width={width || 14} height={height || 8} viewBox="0 0 14 8" fill="none">
			<path
				d="M1.05664 1.12008L6.6795 6.74294C6.72054 6.78667 6.77011 6.82152 6.82515 6.84535C6.88019 6.86918 6.93952 6.88147 6.9995 6.88147C7.05947 6.88147 7.11881 6.86918 7.17385 6.84535C7.22888 6.82152 7.27845 6.78667 7.3195 6.74294L12.9424 1.12008"
				stroke={fill || theme.color.general.white}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};