import React, { CSSProperties } from 'react';
import clsx from 'clsx';

import { StyledTypography } from './styled';

interface IProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	as?: any,
	style?: CSSProperties,
	children?: React.ReactNode,
	className?: string,
	tooltipMaxWidth?: number,
	placement?: string,
	color?: string,
	component?: string,
	fontFamily?: string,
	onClick?: () => void,
	fontSize?: string,
	fontWeight?: string | number,
	lineHeight?: string,
	margin?: string,
	padding?: string,
	textAlign?: 'inherit' | 'left' | 'center' | 'right' | 'justify',
	textDecoration?: string,
	textTransform?: string,
	text?: string | number | React.ReactNode,
	variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'caption1' | 'caption2' | 'caption3' | 'body1' | 'body2' | 'body3' | 'input' | 'button1' | 'button2' |'button3',
}

export const defaultTypeMapping = {
	h1: 'h1',
	h2: 'h2',
	h3: 'h3',
	h4: 'h4',
	caption1: 'h6',
	caption2: 'h6',
	caption3: 'h6',
	body1: 'span',
	body2: 'span',
	body3: 'span',
	input: 'span',
	button1: 'span',
	button2: 'span',
	button3: 'span',
};

const Typography = (props: IProps) => {
	const {
		as,
		placement,
		tooltipMaxWidth,
		children,
		className,
		color,
		component,
		fontFamily,
		fontSize,
		fontWeight,
		lineHeight,
		margin,
		padding,
		textAlign,
		textDecoration,
		textTransform,
		text,
		variant,
		style,
		onClick,
	} = props;

	// @ts-ignore
	const Component: string = as
		|| component
		|| (variant && defaultTypeMapping[variant])
		|| 'span';

	return (
		<StyledTypography
			tooltipMaxWidth={tooltipMaxWidth}
			as={Component}
			variant={variant}
			textAlign={textAlign}
			$textDecoration={textDecoration}
			$textTransform={textTransform}
			color={color}
			margin={margin}
			padding={padding}
			className={clsx(className, variant, 'typography')}
			fontWeight={fontWeight}
			fontFamily={fontFamily}
			fontSize={fontSize}
			lineHeight={lineHeight}
			onClick={onClick}
			placement={placement}
			style={style}
		>
			{children || text}
		</StyledTypography>
	);
};

export default Typography;

Typography.defaultProps = {
	variant: 'body1',
};