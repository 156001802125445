import styled from 'styled-components';
import { IStyledProps } from '../../../types';

export const IconWrapper = styled.div<IStyledProps>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width || 'auto')};
  height: ${({ height }) => (typeof height === 'number' ? `${height}px` : height || 'auto')};
  margin: ${({ margin }) => margin};
  border-radius: ${({ borderRadius }) => borderRadius};
  background-color: ${({ wrapperColor }) => wrapperColor};
  cursor: ${({ onClick }) => !!onClick && 'pointer'};
  flex-shrink: 0;

  &&.disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;