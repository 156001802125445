import React from 'react';
import styled from 'styled-components';
import { IPropsSvgIcon } from '../../types';

const StyledMenuIcon = styled.div<IPropsSvgIcon>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => width || 16}px;
  height: ${({ height }) => height || 16}px;
  margin-top: -1px;
  border-radius: 50%;
  background-color: ${({ wrapperColor, theme }) => wrapperColor || theme.color.general.white};

  & span {
    display: block;
    width: ${({ width }) => width || 16}px;
    height: 1px;
    margin-bottom: -1px;
    background-color: ${({ fill, theme }) => fill || theme.color.general.black};

    &:first-child {
      transform: rotate(45deg);
    }

    &:last-child {
      transform: rotate(-45deg);
    }
  }
`;

const cross = ({
	wrapperColor, fill, height, width,
}: IPropsSvgIcon) => (
	<StyledMenuIcon wrapperColor={wrapperColor} fill={fill} height={height} width={width}>
		<span />
		<span />
	</StyledMenuIcon>
);

export default cross;