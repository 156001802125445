import styled from 'styled-components';

export const StyledDropdown = styled.div`
  position: relative;

  &.opened {

    .icon {
      background-color: ${({ theme }) => theme.color.general.white};

      path {
        stroke: ${({ theme }) => theme.color.general.black};
      }
    }
  }
`;

export const StyledMenu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 2;
  display: none;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.color.general.white};
  filter: drop-shadow(0 14px 15px rgb(0 0 0 / 15%));

  .opened & {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 4px;
  }

  & > * {
    padding: 13px 16px;
    border-radius: 10px;
    white-space: nowrap;
    cursor: pointer;

    .icon {
      margin-right: 10px;
    }

    &:hover {
      background-color: ${({ theme }) => theme.color.general.lightest};

      .icon {
        background-color: ${({ theme }) => theme.color.general.lightest};
      }
    }
  }
`;