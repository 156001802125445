import styled from 'styled-components';
import Image from '../Image';

export const StyledUserpic = styled(Image)`
  position: relative;
  width: fit-content;
  border-radius: 50%;

  .statusIcon {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  img {
    min-width: ${({ width }) => `${width}px` || '36px'};
    border-radius: 50%;
  }
`;