import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const doggySign = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();

	return (
		<svg width={width || 16} height={height || 16} fill="none" viewBox="0 0 16 16">
			<path
				d="M11.4858 8.00131C11.4857 8.67697 11.286 9.33753 10.9117 9.90005C10.5374 10.4626 10.0053 10.902 9.38215 11.1631C8.75898 11.4242 8.07256 11.4953 7.40909 11.3676C6.74562 11.2399 6.13468 10.919 5.65299 10.4452C5.1713 9.97136 4.84033 9.36581 4.70166 8.70454C4.56298 8.04327 4.62278 7.35576 4.87354 6.72837C5.1243 6.10097 5.55484 5.56165 6.1111 5.17813C6.66736 4.79462 7.32453 4.584 8.00009 4.57274C8.45512 4.56516 8.90711 4.64824 9.3297 4.81714C9.7523 4.98604 10.137 5.23739 10.4615 5.55652C10.7859 5.87565 11.0436 6.25618 11.2195 6.67592C11.3953 7.09566 11.4859 7.54622 11.4858 8.00131V8.00131Z"
				stroke={fill || theme.color.general.black}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.4858 8.00084V9.48655C11.4858 13.4751 17.7373 9.71512 14.4573 4.29798C13.7968 3.17003 12.8541 2.23339 11.722 1.58015C10.5898 0.926915 9.30718 0.579568 8.00011 0.572266C6.90202 0.575657 5.81833 0.822453 4.82705 1.29489C3.83577 1.76733 2.96157 2.45366 2.26735 3.30447C1.57313 4.15529 1.07617 5.14943 0.812242 6.21534C0.548313 7.28125 0.523978 8.39242 0.74099 9.46887C0.958001 10.5453 1.41096 11.5603 2.06726 12.4406C2.72357 13.321 3.56689 14.045 4.53654 14.5604C5.50618 15.0757 6.57802 15.3697 7.67492 15.4212C8.77181 15.4727 9.86648 15.2803 10.8801 14.858"
				stroke={fill || theme.color.general.black}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};